import { useQuery } from 'react-query';
import {
  getChatConversationKey,
  getChatRoomsKey,
  getChatRecentConversationKey,
  getChatRoomDetailKey,
  getChatSearchUserKey,
} from './queryKey';
import axiosLib from '../../axiosLib';
import { API_URL } from '../../config';

export const getChatRoomRequest = async () => {
  const response = await axiosLib.get(`${API_URL}/chat/rooms`);
  if (response.data.success) {
    return response.data.rooms;
  }
  return [];
};

export const getChatRoomDetailRequest = async (roomId) => {
  const response = await axiosLib.get(`${API_URL}/chat/rooms/${roomId}`);
  if (response.data.success) {
    return response.data.room;
  }
  return null;
};

export const getChatConversationRequest = async (roomId) => {
  const response = await axiosLib.get(`${API_URL}/chat/conversation/${roomId}`);
  return response.data;
};

export const getChatUserSearchRequest = async () => {
  const response = await axiosLib.get(`${API_URL}/user/all`);
  if (response.data.success) {
    return response.data.users;
  }
  return [];
};

export const getChatRecentConversationRequest = async () => {
  const response = await axiosLib.get(`${API_URL}/chat/`);
  if (response.data.success) {
    return response.data.conversation;
  }
  return [];
};

export const useGetChatRooms = (enabled) => {
  return useQuery(getChatRoomsKey(), async () => getChatRoomRequest(), {
    enabled: enabled,
  });
};

export const useGetChatConversation = (roomId) => {
  return useQuery(getChatConversationKey(roomId), async () =>
    getChatConversationRequest(roomId)
  );
};

export const useGetChatRoomDetail = (roomId) => {
  return useQuery(getChatRoomDetailKey(roomId), async () =>
    getChatRoomDetailRequest(roomId)
  );
};

export const useGetChatRecentConversation = (enabled) => {
  return useQuery(
    getChatRecentConversationKey(),
    async () => getChatRecentConversationRequest(),
    {
      enabled: enabled,
    }
  );
};

export const useGetUserSearch = (enabled) => {
  return useQuery(
    getChatSearchUserKey(),
    async () => getChatUserSearchRequest(),
    {
      enabled: enabled,
    }
  );
};
