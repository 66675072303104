import React, { useMemo, useState } from "react";
import "./style.scss";
import moment from "moment";
import Avatar from "react-avatar";
import ChatRoomSearch from "./ChatRoomSerach";
import ChatRoomTabs from "./ChatRoomTabs";
import { useCreateChatRoom, useGetUserSearch, useUpdateChatName } from "../../client-api";
const ChatRooms = ({ rooms, currentRoom, setCurrentRoom, account, setCreateNewRoomFlag }) => {
  const [search, setSearch] = useState("");
  const [tabType, setTabType] = useState("all");
  const [editChatNameModalOpen, setEditChatNameModalOpen] = useState(undefined);
  const [newChatName, setNewChatName] = useState('');

  const totalUnreadCount = rooms.reduce(
    (n, { unreadCount }) => n + unreadCount,
    0
  );

  const { data: userSearchData } = useGetUserSearch(!!account);
  const { mutateAsync: createNewChatRoom } = useCreateChatRoom();
  const { mutate: mutateUpdateChatName } = useUpdateChatName();

  const onCreateNewChatRoom = async (userId) => {
    const chatRoom = await createNewChatRoom([userId]);
    if (chatRoom) {
      setCurrentRoom(chatRoom.chatRoomId);
    }
  };

  const filteredRooms = useMemo(() => {
    if (search) {
      return rooms.filter((room) =>
        room.userIds.some((user) =>
          user.name.toLowerCase().includes(search.toLowerCase())
        )
      );
    }
    if (tabType === "unread") {
      return rooms.filter((room) => room.unreadCount > 0);
    }
    return rooms;
  }, [search, tabType, rooms]);

  const filteredUsers = useMemo(() => {
    if (search) {
      const roomUsers = rooms
        .flatMap((room) => room.userIds)
        .map((user) => user._id);
      return userSearchData.filter(
        (user) =>
          !roomUsers.includes(user._id) &&
          user._id !== account._id &&
          user.name.toLowerCase().includes(search.toLowerCase())
      );
    }
    return [];
  }, [search, userSearchData, rooms]);


  const onEditChatName = () => {
    mutateUpdateChatName(
      { roomId: editChatNameModalOpen, name: newChatName },
      {
        onSuccess: () => {
          setEditChatNameModalOpen(undefined);
          setNewChatName("");
        },
        onError: (err) => {
          console.error("Error updating chat name:", err);
        },
      }
    );
  }

  return (
    <>
      <div className="Chat-rooms-container" style={{ position: 'relative' }}>
        <ChatRoomSearch search={search} setSearch={setSearch} />
        <ChatRoomTabs
          tabType={tabType}
          setTabType={setTabType}
          unreadCount={totalUnreadCount}
        />
        {filteredRooms.map((room) => {

          const activeClass = currentRoom === room._id ? "Chat-room-active" : "";
          const activeUsers = room.userIds.filter(
            (user) => user._id !== account._id
          );
          const lastMessageStr = room.lastMessage ?? "";
          const lastMessageText =
            lastMessageStr.length > 30
              ? `${lastMessageStr.slice(0, 20)}...`
              : lastMessageStr;
          const lastTime = moment(room.updatedAt).fromNow();

          return (
            <div
              key={room._id}
              className={`Chat-room ${activeClass}`}
              onClick={() => {
                setCreateNewRoomFlag(false);
                setCurrentRoom(room._id);
              }}
            >
              <div className="Chat-room-avatar">
                <Avatar
                  name={activeUsers[0].name}
                  src={activeUsers[0].profileImg}
                  size={50}
                  round={true}
                />
              </div>

              <div className="Chat-room-middle">
                <p className="Chat-room-middle-name">
                  {room.name && room.name !== '' ? room.name : activeUsers.map(user => user.name).join(',')}
                </p>
                <p className="Chat-room-middle-last">
                  {lastMessageText} {lastTime}
                </p>
              </div>

              <div className="Chat-room-end">
                {room.unreadCount > 0 && (
                  <div className="Chat-room-badge">{room.unreadCount}</div>
                )}

                <div
                  className='edit-chat-name-button'
                  onClick={(e) => {

                    e.stopPropagation();
                    setEditChatNameModalOpen(room._id);
                    setNewChatName(room.name);
                  }}
                >
                  編集
                </div>
              </div>
            </div>
          );
        })}
        {filteredUsers.map((user) => {
          return (
            <div key={user._id} className={`Chat-room`}>
              <div
                style={{
                  width: "80%",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  gap: "10px",
                }}
              >
                <Avatar
                  name={user.name}
                  src={user.profileImg}
                  size={50}
                  round={true}
                />
                <p
                  style={{
                    fontSize: "1.1rem",
                    fontWeight: "700",
                    color: "#1E1F23",
                  }}
                >
                  {user.name}
                </p>
              </div>
              <p
                onClick={() => {
                  onCreateNewChatRoom(user._id);
                }}
                style={{
                  display: "flex",
                  flex: 1,
                  fontSize: "0.8rem",
                  fontWeight: "700",
                  color: "#2B4773",
                  cursor: "pointer",
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                  textDecoration: "underline",
                }}
              >
                DMを送る
              </p>
            </div>
          );
        })}
        <button
          onClick={() => {
            setCurrentRoom(null);
            setCreateNewRoomFlag(true);
          }}
          style={{
            position: 'absolute',
            bottom: 20,
            right: 20,
            width: 40,
            height: 40,
            background: '#b6322e',
            color: '#ffffff',
            textAlign: "center",
            borderRadius: '50%',
            fontSize: 24,
            cursor: 'pointer',
          }}
        >
          +
        </button>
      </div>

      {editChatNameModalOpen ? (
        <div
          className="modal-edit"
          onClick={() => {
            setEditChatNameModalOpen(undefined);
            setNewChatName('');
          }}
        >
          <div
            className='edit-chat-name-modal'
            style={{
              position: "fixed",
              top: "50%",
              left: "50%",
              transform: "translate(-50%,-50%)",
              backgroundColor: "white",
              padding: "20px",
              borderRadius: "10px",
              height: "250px",
              textAlign: "center",
              display: editChatNameModalOpen ? "block" : "none"
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "start",
                alignItems: 'start',
                height: "100%",
              }}
            >
              <p style={{
                fontWeight: "bold",
                fontSize: "19px",
              }}>
                新しいチャット名
              </p>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: '100%',
                }}
              >
                <input
                  type="text"
                  maxLength={30}
                  value={newChatName}
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                  onChange={(e) => setNewChatName(e.target.value)}
                  style={{
                    width: "100%",
                    margin: "20px 0",
                    padding: "8px",
                    fontSize: "16px",
                  }}
                />
                <button
                  className="edit-chat-name-button"
                  style={{
                    padding: "8px",
                    borderRadius: "5px",
                    cursor: "pointer",
                    fontSize: "16px",
                    width: "20%",
                    alignSelf: "flex-end",
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                    onEditChatName()
                  }}
                >
                  編集
                </button>
              </div>
            </div>
          </div>

        </div>) : null}
    </>
  );
};
export default ChatRooms;
