import React, { useEffect, useState, useContext, useRef } from "react";
import { StoreContext } from "../../store/store";
import { withRouter, useHistory, Link } from "react-router-dom";
import "./style.scss";
import moment from "moment";
import Loader from "../Loader";
import {
  ICON_ARROWBACK,
  ICON_HEART,
  ICON_REPLY,
  ICON_IMGUPLOAD,
  ICON_CLOSE,
} from "../../Icons";
import axiosLib from "../../axiosLib";
import { API_URL } from "../../config";
import ContentEditable from "react-contenteditable";
import CarvibesCard from "../CarvibesCard";
import dayjs from "dayjs";
import { useLocalization } from "../../hooks/useLocalization";
import { supportedImageFiletypes } from "../../utils/common";
import { getDatetimeFormat } from "../../utils/common";
import UserAvatar from "../UserAvatar";

const SCREEN_TEXT = ["投稿する"];

const CarvibesPage = (props) => {
  let history = useHistory();

  const { state, actions } = useContext(StoreContext);
  const { carvibes, account, session } = state;

  const { countryCode, translatedTexts } = useLocalization(SCREEN_TEXT);

  const [modalOpen, setModalOpen] = useState(false);
  const [replyText, setReplyText] = useState("");
  const [replyImage, setReplyImg] = useState(null);
  const [imageLoaded, setImageLoaded] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    actions.getCarvibes(props.match.params.id);
  }, [props.match.params.id]);
  var image = new Image();

  const isInitialMount = useRef(true);

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
    } else if (document.getElementById("replyBox")) {
      document.getElementById("replyBox").focus();
    }
  }, [modalOpen]);

  let info;
  const likeCarvibes = (id) => {
    if (!session) {
      actions.alert("Please Sign In");
      return;
    }
    info = { dest: "carvibes", id };
    actions.likeCarvibes(info);
    setTimeout(() => {
      actions.verifyToken("get account");
    }, 1000);
  };
  const recarvibes = (id) => {
    if (!session) {
      actions.alert("Please Sign In");
      return;
    }
    info = { dest: "carvibes", id };
    actions.recarvibes(info);
  };
  const bookmarkCarvibes = (id) => {
    if (!session) {
      actions.alert("Please Sign In");
      return;
    }
    info = { dest: "carvibes", id };
    actions.bookmarkCarvibes(info);
  };
  const deleteCarvibes = (id) => {
    actions.deleteCarvibes(id);
  };

  const uploadImage = (files) => {
    let bodyFormData = new FormData();
    for (let i = 0; i < files.length; i++) {
      bodyFormData.append("images", files[i]);
    }

    axiosLib
      .post(`${API_URL}/carvibes/upload`, bodyFormData)
      .then((res) => {
        let imgUrl =
          res.data.imageUrls.length > 0 ? res.data.imageUrls[0] : null;

        setReplyImg(imgUrl);
      })
      .catch((err) => {
        console.log(err);
        alert("error uploading image");
      });
  };

  const onchangeImage = () => {
    let files = document.getElementById("image").files;
    uploadImage([files[0]]);
  };

  const removeImage = () => {
    document.getElementById("image").value = "";
    setReplyImg(null);
    setImageLoaded(false);
  };

  const toggleModal = (e, type) => {
    if (e) {
      e.stopPropagation();
    }
    // if(param === 'edit'){setSaved(false)}
    // if(type === 'parent'){setParent(true)}else{setParent(false)}
    setModalOpen(!modalOpen);
    if (!modalOpen) {
      document.documentElement.classList.add("scroll-disabled");
    } else {
      document.documentElement.classList.remove("scroll-disabled");
    }
  };

  const handleModalClick = (e) => {
    e.stopPropagation();
  };

  const carvibesT = useRef("");
  const handleChange = (evt) => {
    carvibesT.current = evt.target.value;
    setReplyText(carvibesT.current);
  };

  const replyCarvibes = (type) => {
    toggleModal();
    let hashtags = replyText.match(/#(\w+)/g);
    if (!replyText.length) {
      return;
    }
    const values = {
      description: replyText,
      images: [replyImage],
      parent: props.match.params.id,
      hashtags,
    };
    actions.carvibes(values);
    carvibesT.current = "";
    setReplyText("");
    setReplyImg(null);
    actions.alert("Carvibes sent!");
  };

  const goBack = () => {
    history.goBack();
  };

  const updateCarvibes = (id) => {
    actions.getCarvibes(id);
  };

  return (
    <>
      {carvibes ? (
        <div className="carvibes-wrapper">
          <div className="carvibes-header-wrapper">
            <div className="profile-header-back">
              <div onClick={() => goBack()} className="header-back-wrapper">
                <ICON_ARROWBACK />
              </div>
            </div>
            <div className="carvibes-header-content">{translatedTexts[0]}</div>
          </div>
          <div className="carvibes-body-wrapper">
            <div className="carvibes-header-content">
              <div className="carvibes-user-pic">
                <Link to={`/profile/${carvibes.user.username}`}>
                  <UserAvatar user={carvibes.user} />
                </Link>
              </div>
              <div className="carvibes-user-wrap">
                <div className="carvibes-user-name">{carvibes.user.name}</div>
              </div>
            </div>
            <div className="carvibes-content">
              {carvibes.description.replaceAll("<br>", "\n")}
            </div>
            {carvibes.images[0] ? (
              <div className="carvibes-image-wrapper">
                <div
                  style={{
                    backgroundImage: `url(${carvibes.images[0]})`,
                    paddingBottom: `${
                      ((image.src = carvibes.images[0]),
                      100 / (image.width / image.height))
                    }%`,
                  }}
                ></div>
              </div>
            ) : null}
            <div className="carvibes-interactions">
              <div onClick={() => toggleModal()} className="carvibes-int-icon">
                <div className="card-icon reply-icon">
                  {" "}
                  <ICON_REPLY />{" "}
                </div>
              </div>
              <div
                onClick={() => likeCarvibes(carvibes._id)}
                className="carvibes-int-icon"
              >
                <div className="card-icon heart-icon">
                  <ICON_HEART />
                </div>
              </div>
            </div>
          </div>

          {carvibes.replies.map((r) => {
            return (
              <CarvibesCard
                showFollow={true}
                carvibesUpdated={(id) => {
                  updateCarvibes(id);
                }}
                recarvibes={r.recarvibes}
                username={r.username}
                name={r.name}
                replyTo={carvibes.user.username}
                key={r._id}
                id={r._id}
                user={r.user}
                createdAt={r.createdAt}
                description={r.description}
                images={r.images}
                replies={r.replies}
                likes={r.likes}
                hashtags={r.hashtags}
                categories={r.categories}
              />
            );
          })}
        </div>
      ) : (
        <div className="carvibes-wrapper">
          <Loader />
        </div>
      )}

      {carvibes && account ? (
        <div
          onClick={() => toggleModal()}
          style={{ display: modalOpen ? "block" : "none" }}
          className="modal-edit"
        >
          {modalOpen ? (
            <div
              style={{ minHeight: "379px", height: "initial" }}
              onClick={(e) => handleModalClick(e)}
              className="modal-content"
            >
              <div className="modal-header">
                <div className="modal-closeIcon">
                  <div
                    onClick={() => toggleModal()}
                    className="modal-closeIcon-wrap"
                  >
                    <ICON_ARROWBACK />
                  </div>
                </div>
                <p className="modal-title">Reply</p>
              </div>
              <div style={{ marginTop: "5px" }} className="modal-body">
                <div className="reply-content-wrapper">
                  <div className="card-userPic-wrapper">
                    <Link
                      onClick={(e) => e.stopPropagation()}
                      to={`/profile/${carvibes.user.username}`}
                    >
                      <UserAvatar user={carvibes.user} />
                    </Link>
                    <div className="card-content-header">
                      <div className="card-header-detail">
                        <span className="card-header-user">
                          <Link
                            onClick={(e) => e.stopPropagation()}
                            to={`/profile/${carvibes.user.username}`}
                          >
                            {carvibes.user.name}
                          </Link>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="card-content-wrapper">
                    <div className="card-content-info">
                      {carvibes.description.replaceAll("<br>", "\n")}
                      <br />
                      {dayjs(carvibes.createdAt).format(
                        getDatetimeFormat(countryCode)
                      )}
                    </div>
                  </div>
                </div>
                <div
                  style={{ position: "relative" }}
                  className="Carvibes-input-wrapper"
                >
                  <div className="Carvibes-profile-wrapper">
                    <div>
                      <UserAvatar user={account} />
                    </div>
                  </div>
                  <div
                    onClick={() => document.getElementById("replyBox").focus()}
                    className="Carvibes-input-side"
                  >
                    <div className="inner-input-box">
                      <ContentEditable
                        id="replyBox"
                        style={{
                          minHeight: "30px",
                          minWidth: "100px",
                        }}
                        className={
                          replyText.length ? "carvibes-input-active" : null
                        }
                        placeholder="Reply"
                        html={carvibesT.current}
                        onChange={handleChange}
                      />
                    </div>
                    {replyImage && (
                      <div className="inner-image-box">
                        <img
                          onLoad={() => setImageLoaded(true)}
                          className="carvibes-upload-image"
                          src={replyImage}
                          alt="carvibes"
                        />
                        {imageLoaded && (
                          <span onClick={removeImage} className="cancel-image">
                            x
                          </span>
                        )}
                      </div>
                    )}
                    <div className="inner-input-links">
                      <div className="input-links-side">
                        <div
                          style={{ marginLeft: "-10px" }}
                          className="input-attach-wrapper"
                        >
                          <ICON_IMGUPLOAD
                            styles={{ fill: "rgb(29, 161, 242)" }}
                          />
                          <input
                            title=" "
                            id="image"
                            style={{ opacity: "0" }}
                            type="file"
                            multiple={true}
                            accept={supportedImageFiletypes.join(", ")}
                            onChange={() => onchangeImage()}
                          />
                        </div>
                      </div>
                      <div className="carvibes-btn-holder">
                        <div
                          onClick={() => replyCarvibes("parent")}
                          className={
                            replyText.length
                              ? "carvibes-btn-side carvibes-btn-active"
                              : "carvibes-btn-side"
                          }
                        >
                          {translatedTexts[0]}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : null}
        </div>
      ) : null}
    </>
  );
};

export default withRouter(CarvibesPage);
