import React, { useContext, useState } from 'react';
import { StoreContext } from '../../store/store';
import Loading from '../loading';
import { withRouter } from 'react-router-dom';
import {
  useGetChatRecentConversation,
  useGetChatRooms,
} from '../../client-api';
import './style.scss';
import ChatRooms from './ChatRooms';
import ChatMessages from './ChatMessages';
import ChatUsersSelect from './ChatUsersSelect';
import CreateGroupChatButton from './CreateGroupChatButton';
import { MediaQuery, useMediaQuery } from '../../hooks/useMediaQuery';
import { ICON_ARROWBACK } from "../../Icons";
import CreateDMButton from './CreateDmChatButton';

const Chat = () => {
  const { state, actions } = useContext(StoreContext);
  const { account } = state;
  const [currentRoom, setCurrentRoom] = useState(null);
  const [createNewRoomFlag, setCreateNewRoomFlag] = useState(false);
  const [openUsers, setOpenUsers] = useState(false);
  const isSM = useMediaQuery(MediaQuery.sm);
  const [chatMode, setChatMode] = useState(null); // New state to track mode: 'dm' or 'group'

  const queryEnabled = !!account;
  const {
    data: rooms,
    error: roomsError,
    isLoading: roomsLoading,
  } = useGetChatRooms(queryEnabled);
  const {
    data: recentConversations,
    error: recentConversationError,
    isLoading: recentChatLoading,
  } = useGetChatRecentConversation(queryEnabled);
  const dataLoading = !rooms || !recentConversations;

  const loading = dataLoading || roomsLoading || recentChatLoading;

  if (loading) return Loading();

  return (
    <div className="Chat-container">
      {isSM ? (
        <>
          <ChatRooms
            account={account}
            rooms={rooms}
            currentRoom={currentRoom}
            setCurrentRoom={setCurrentRoom}
            setCreateNewRoomFlag={setCreateNewRoomFlag}
          />
          {currentRoom && (
            <ChatMessages
              roomId={currentRoom}
              account={account}
              onBack={() => setCurrentRoom(null)}
              createNewRoomFlag={createNewRoomFlag}
            />
          )}
          {createNewRoomFlag && (
            <div style={{ width: '70%', margin: '0 auto' }}>
              <CreateDMButton
                setOpenUsers={setOpenUsers}
                setChatMode={setChatMode}
              />
              <CreateGroupChatButton
                setOpenUsers={setOpenUsers}
                setChatMode={setChatMode}
              />
            </div>
          )}
        </>
      ) : (
        <>
          {!currentRoom && !createNewRoomFlag && (
            <ChatRooms
              account={account}
              rooms={rooms}
              currentRoom={currentRoom}
              setCurrentRoom={setCurrentRoom}
              setCreateNewRoomFlag={setCreateNewRoomFlag}
            />
          )}
          {currentRoom && (
            <ChatMessages
              roomId={currentRoom}
              account={account}
              onBack={() => setCurrentRoom(null)}
              createNewRoomFlag={createNewRoomFlag}
            />
          )}
          {createNewRoomFlag && (
            <>
              <div
                onClick={() => setCreateNewRoomFlag(false)}
                style={{
                  height: 'fit-content',
                  marginTop: 20,
                  marginLeft: 10,
                  cursor: "pointer",
                }}
              >
                <ICON_ARROWBACK />
              </div>
              <div style={{ width: '80%', margin: '0' }}>
                <CreateDMButton
                  setOpenUsers={setOpenUsers}
                  setChatMode={setChatMode}
                />
                <CreateGroupChatButton
                  setOpenUsers={setOpenUsers}
                  setChatMode={setChatMode}
                />
              </div>
            </>
          )}
        </>
      )}
      {openUsers && (
        <ChatUsersSelect
          account={account}
          setOpenUsers={setOpenUsers}
          setCurrentRoom={setCurrentRoom}
          setCreateNewRoomFlag={setCreateNewRoomFlag}
          chatMode={chatMode}
        />
      )}
    </div>
  );
};

export default withRouter(Chat);