import React, { useEffect, useContext, useState } from "react";
import "./style.scss";
import { withRouter } from "react-router-dom";
import { StoreContext } from "../../../store/store";
import TableHeaderItem from "../../Admin/components/TableHeaderItem";
import UserAvatar from "../../UserAvatar";
import Loading from "../../loading";
import TableItem from "../../Admin/components/TableItem";
import { getDatetimeFormat, timeDisplay } from "../../../utils/common";
import InformModal from "../../InformModal";
import dayjs from "dayjs";

const UserDetail = (props) => {
  const { state, actions } = useContext(StoreContext);
  const { loading, user } = state;
  const [warned, setWarned] = useState(false);
  const [suspended, setSuspended] = useState(false);

  useEffect(() => {
    const currentToken = localStorage.getItem("carvibestoken");
    if (!(currentToken && currentToken.length > 0)) {
      window.location = "/admin";
    }
    window.scrollTo(0, 0);
    actions.getUser(props.match.params.userid);
  }, [props.match.params.userid]);

  useEffect(
    () => () => (document.getElementsByTagName("body")[0].style.cssText = ""),
    []
  );

  const tableHeader = () => {
    return (
      <div
        className="admin-carvibes-cell-title"
        style={{
          flexDirection: "row",
          height: "40px",
          backgroundColor: "#F7FAFC",
        }}
      >
        <TableHeaderItem title="投稿画像" width="200px" />
        <TableHeaderItem title="投稿文章" width="320px" />
        <TableHeaderItem title="投稿日時" width="auto" />
      </div>
    );
  };

  if (loading || !user) return Loading();

  const carvibesImage = (image) => {
    if (!image) {
      return (
        <div
          style={{
            backgroundColor: "#A0AEC0",
            width: "32px",
            height: "32px",
            borderRadius: "4px",
          }}
        ></div>
      );
    }
    return <img src={image} alt="carvibes" className="admin-carvibes-image" />;
  };

  const carvibesImages = (images) => {
    if (!images)
      return (
        <div
          style={{
            backgroundColor: "#A0AEC0",
            width: "32px",
            height: "32px",
            borderRadius: "4px",
          }}
        ></div>
      );
    return images.map((i) => {
      return (
        <div
          key={i}
          style={{
            flexDirection: "row",
            paddingRight: "10px",
          }}
        >
          {carvibesImage(i)}
        </div>
      );
    });
  };

  const onClickWarn = () => {
    setWarned(true);
    actions.warnUser(user._id);
  };

  const onClickSuspend = () => {
    setSuspended(true);
    actions.deleteUser(user._id);
  };

  const warnedOrSuspended = warned || suspended;
  const warningMessage = warned
    ? "このユーザーは警告されています"
    : "このユーザーは利用停止されています";
  const handleModalClick = () => {
    setWarned(false);
    setSuspended(false);
  };

  return (
    <div
      className="admin-wrapper"
      style={{
        backgroundColor: "#fff",
      }}
    >
      <div className="admin-header-wrapper">
        <h2 className="admin-title">ユーザー管理＞ユーザー詳細</h2>
      </div>
      <div className="user-container">
        <div className="user-info-container">
          <UserAvatar user={user} size={240} round={12} />
          <div
            className="user-info"
            style={{
              marginTop: "16px",
            }}
          >
            <p
              className="admin-header-title"
              style={{
                padding: "0",
              }}
            >
              UserID: {user._id}
            </p>
            <p
              className="admin-header-title"
              style={{
                padding: "0",
                fontSize: "14px",
                fontWeight: "700",
              }}
            >
              {user.name}
            </p>
            <div
              style={{
                width: "100%",
                display: "flex",
                marginTop: "16px",
              }}
            >
              <button
                className="admin-read-button"
                style={{
                  width: "50%",
                  marginRight: "12px",
                }}
                onClick={onClickWarn}
              >
                警告
              </button>
              <button
                className="suspend-user-button"
                style={{
                  width: "50%",
                }}
                onClick={onClickSuspend}
              >
                利用停止
              </button>
            </div>
          </div>
        </div>
        <div className="user-post-list-container">
          {tableHeader()}
          {user.carvibess.length > 0 ? (
            user.carvibess.map((c) => {
              return (
                <div className="admin-carvibes-cell" key={c._id}>
                  <div
                    style={{
                      width: "200px",
                      display: "inline-flex",
                    }}
                  >
                    {carvibesImages(c.images)}
                  </div>
                  <TableItem
                    title={c.description}
                    width="320px"
                    paddingRight="8px"
                  />
                  <TableItem
                    title={timeDisplay(c.createdAt)}
                    width="200px"
                  />
                </div>
              );
            })
          ) : (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100px",
                width: "100%",
              }}
            >
              <span
                style={{
                  fontSize: "16px",
                  fontWeight: "400",
                }}
              >
                報告された投稿はありません
              </span>
            </div>
          )}
        </div>
      </div>
      {warnedOrSuspended && (
        <InformModal
          isOpen={warnedOrSuspended}
          title={warningMessage}
          onClose={handleModalClick}
        />
      )}
    </div>
  );
};

export default withRouter(UserDetail);
