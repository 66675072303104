import React, { useState } from "react";
import { useLocalization } from "../../hooks/useLocalization";
import { Link, useHistory } from "react-router-dom";
import "./style.scss";

const LOGIN_SCREEN_TEXT = [
  "あなたの愛車でココロ踊る体験を。",
  `現在、世界中のクルマ好き XXX 人 が全力応援中!!`,
  `現在、世界中のクルマ XXX 台 が全力稼働中!!`,
  "ログインして始めましょう！",
  "Googleアカウントでログイン/登録を行います。",
  "認証できない場合はお問合せください。",
  "運営会社",
  "利用規約",
  "プライバシーポリシー",
  "お問合せ",
  "運営会社",
];

const CompanyPage = () => {
  const { translatedTexts } = useLocalization(LOGIN_SCREEN_TEXT);
  const [showCareerDetails, setShowCareerDetails] = useState(false);
  const history = useHistory();

  const toggleCareerDetails = () => {
    setShowCareerDetails(!showCareerDetails);
  };

  const onBackButton = () => history.goBack();

  return (
    <div className='company-outer'>
      <header className="company-header">
        <img src="/logo.png" alt="Header Logo" className="company-header-logo" />
      </header>

      <div className='company-content'>
        <div className="back-link">
          <img src="/chevron-right.png" alt="chevron-right" className='chevron-right' onClick={onBackButton} />
          <span onClick={onBackButton}>前ページへ戻る</span>
        </div>

        <div className="tf-asset-management">
          {/* logo */}
          <div className="logo">
            <p> Company Logo </p>
            <img src="t&f-logo.png" alt="T&F Asset Management Logo" />
          </div>

          <div className='info-group-container'>
            {/* Corporate Name */}
            <div className='info-section-container'>
              <div className='info-key-section'>
                <div className="label-jp">社名</div>
                <div className="label-en">Corporate name</div>
              </div>

              <span className="vertical-divider" />

              <div className='info-value-section'>
                <div className="label-jp">T&Fアセットマネジメント株式会社</div>
                <div className="label-en">T&F ASSET MANAGEMENT INC.</div>
              </div>
            </div>

            <div className="divider" />

            {/* Vision */}
            <div className='info-section-container'>
              <div className='info-key-section'>
                <div className="label-jp">ビジョン</div>
                <div className="label-en">Vision</div>
              </div>

              <span className="vertical-divider" />

              <div className='info-value-section'>
                <div className="label-jp">“記憶に残る価値を”</div>
                <div className="label-en">Memorable value.</div>
              </div>
            </div>

            <div className="divider" />

            {/* Mission */}
            <div className='info-section-container'>
              <div className='info-key-section'>
                <div className="label-jp">ミッション</div>
                <div className="label-en">Mission</div>
              </div>

              <span className="vertical-divider" />

              <div className='info-value-section'>
                <div className="label-jp">“あくなき探究心を持って、価値あるビジネスを創造し続ける”</div>
                <div className="label-en">Work with an inquisitive mind, Continue to create valuable business.</div>
              </div>
            </div>

            <div className="divider" />

            {/* HQ */}
            <div className='info-section-container'>
              <div className='info-key-section'>
                <div className="label-jp">活動拠点</div>
                <div className="label-en">HeadQuarters</div>
              </div>

              <span className="vertical-divider" />

              <div className='info-value-section'>
                <div className="label-jp">日本・その他海外</div>
                <div className="label-en">Japan / Other overseas</div>
              </div>
            </div>

            <div className="divider" />

            {/* CEO */}
            <div className='info-section-container'>
              <div className='info-key-section'>
                <div className="label-jp">創業者</div>
                <div className="label-en">CEO</div>
              </div>

              <span className="vertical-divider" style={{
                marginBottom: showCareerDetails ? '50px' : '0px'
              }} />

              <div className='info-value-section'>
                <div className='info-ceo-career-section'>
                  <div className='info-ceo-section'>
                    <img src="/ceo.png" alt="CEO" className={`ceo-image ${showCareerDetails ? 'ceo-image-large' : ''}`} />

                    <div>
                      <div className='info-ceo-name'>
                        <div className='font-inter'>大越 隆行</div>
                        <div className='font-inter'>Takayuki Ogoshi</div>
                      </div>
                      <div className='info-ceo-details-group'>
                        <div className='info-ceo-details'>
                          1985年生まれ、神奈川県横浜市出身。
                        </div>
                        <div className='info-ceo-details'>
                          大学卒業後、人材系ベンチャー企業groovesに入社。
                          営業としてキャリアを積む。
                        </div>
                        <div className='info-ceo-details'>
                          2010年にアマゾンジャパン合同会社へ入社し、最初携わった営業職の
                          ポジション
                          において、
                          入社6ヶ月でトップセールスを獲得。
                          そして当時26歳の若さで、グローバルで世界最年少の事業責任者に就任。
                          新規事業の立ち上げも経験し、年商18億円から年商55億円へと成長へ牽引。
                        </div>
                        <div className='info-ceo-details'>
                          2015年、さらなるキャリアアップを求め、ランサーズ株式会社に入社。
                          海外フィリピンにて、
                          Lancers Philippinesを創業。
                          同社取締役を務める。
                          海外事業展開の経験も持ち、大きく事業へ貢献。
                        </div>
                        <div className='info-ceo-details'>
                          2016年9月、自身で初めての起業となる「TAAS (ターズ)」を創業。
                          オフィスサイネージという新たな分野を自ら創出し、e-Pod Digital事業を展開。
                          2018年5月、KDDI∞Labo 第12期採択企業。1部上場企業や資金調達などもしたものの
                          2020年に発生したコロナウイルスの大打撃を受け、2022年にTAAS株式会社を自ら解散。
                        </div>
                        <div className='info-ceo-details'>
                          そしてゼロから再び歩みを始め、根っからの「クルマへの愛情、クルマが好き」
                          という思いを具現化すべく、
                          世界中の
                          クルマ好きの為に特化したSNSサービス
                          「CarVibes」を着想し、発案・企画・開発。現在に至る。
                        </div>

                      </div>

                    </div>
                  </div>

                  <div className='info-career-section'>
                    <div className="info-toggle-details" onClick={toggleCareerDetails}>
                      <img src="/chevron-down.png" alt="toggle details" className={showCareerDetails ? 'rotated' : ''} />
                      <span style={{ color: 'black', fontWeight: 'bold', fontSize: '14px' }}>詳細</span>
                    </div>
                    <div className="divider" style={{ margin: 0 }} />

                    {showCareerDetails && (
                      <>
                        {/* Career */}
                        <div className="info-career-details" style={{ marginTop: '5px' }} >
                          <div className="info-career-header">
                            Career /経歴
                          </div>

                          <div className="info-career-description">
                            <div className="info-carrer-description-item">
                              <span>2007年3月</span>
                              <span>関東学院大学 人間環境学部卒</span>
                            </div>
                          </div>

                          <div className="info-career-description">
                            <div className="info-carrer-description-item">
                              <span>2007-2010</span>
                              <span>株式会社grooves (未上場ベンチャー) </span>
                            </div>
                          </div>

                          <div className="info-career-description">
                            <div className="info-carrer-description-item">
                              <span>2010-2015</span>
                              <span>アマゾンジャパン合同会社 (NASDAQ上場企業)</span>
                            </div>
                          </div>

                          <div className="info-career-description">
                            <div className="info-carrer-description-item">
                              <span>2015-2016</span>
                              <span>ランサーズ株式会社（旧マザーズ：現グロース上場企業）</span>
                            </div>
                          </div>

                          <div className="info-career-description">
                            <div className="info-carrer-description-item">
                              <span>2015-2016</span>
                              <span>Lancers Philippines,Inc. 設立、同社取締役（フィリピン法人）</span>
                            </div>
                          </div>

                          <div className="info-career-description">
                            <div className="info-carrer-description-item">
                              <span>2016-2022</span>
                              <span>TAAS株式会社 創業・同社代表取締役兼CEO (創業者) </span>
                            </div>
                          </div>

                          <div className="info-career-description">
                            <div className="info-carrer-description-item">
                              <span>現在〜</span>
                              <span>T&Fアセットマネジメント株式会社 代表取締役兼CEO（創業者・現職）</span>
                            </div>
                          </div>

                        </div>

                        {/* IP */}
                        <div className="info-career-details">
                          <div className="info-career-header">
                            IP /知的財産・特許権
                          </div>

                          <div className="info-ip-description">
                            <span>特許番号</span>
                            <span>：特許 第6564157号 （日本国内における発明者・特許権者）</span>
                          </div>

                          <div className="info-ip-description">
                            <span>発明の名称</span>
                            <span>：廃棄物回収サービスとコンテンツ配信サービスとを統合するためのシステム</span>
                          </div>

                        </div>

                        {/* Awards */}
                        <div className="info-career-details">
                          <div className="info-career-header">
                            Award /受賞歴（1回目に自身で起業したTAAS時代も含む）
                          </div>
                          <div className="info-award-description">
                            <span>• 2019年4月</span>
                            <span>みずほ銀行「Mizuho Innovation Award 2019」受賞。</span>
                          </div>

                          <div className="info-award-description">
                            <span>• 2019年10月</span>
                            <span>アジア18ヶ国対象の「ASIA GOLDEN STAR AWARD」受賞</span>

                          </div>
                        </div>

                        {/* main topics */}
                        <div className="info-career-details">
                          <div className="info-career-header">
                            キャリアの中での主なトピック
                          </div>
                          <div className="info-career-description">
                            • Amazon史上初 日本人で初めて世界最年少での事業責任者就任(26歳当時)
                          </div>

                          <div className="info-career-description">
                            • Amazonにて自身で事業を任された事業を約2年で18億円→55億円まで急成長させ、事業を牽引
                          </div>

                          <div className="info-career-description">
                            • Amazonにて数々の世界初の新規事業を創出し世界中で展開。
                          </div>

                          <div className="info-career-description">
                            • 海外「Lancers Philippines Inc.(フィリピン法人)」創業・同社取締役。
                          </div>
                        </div>

                        {/*  first service  */}
                        <div className="info-career-details">
                          <div className="info-career-header">
                            手掛けてきた世界初のサービス
                          </div>
                          <div className="info-career-description">
                            • オフィスサイネージメディア「e-Pod Digital」
                          </div>

                          <div className="info-career-description">
                            • Amazonでのバイクや車体販売
                          </div>

                          <div className="info-career-description">
                            • Amazon Boxのラッピングサービス
                          </div>

                          <div className="info-career-description">
                            • Amazon Adult ADなど多数
                          </div>
                        </div>

                        {/* media */}
                        <div className="info-career-details" style={{ marginBottom: 0 }}>
                          <div className="info-career-header">
                            Media /メディア（1回目に自身で起業したTAAS時代も含む）
                          </div>
                          <div className="info-career-description">
                            • 各種オンラインメディアへ100メディア以上で掲載多数
                          </div>

                          <div className="info-career-description">
                            • テレビ東京「ワールドビジネスサテライト」出演
                          </div>

                          <div className="info-career-description">
                            • フジテレビ「The NEWS α」出演
                          </div>

                          <div className="info-career-description">
                            • BS朝日「Fresh Faces〜アタラシイヒト〜」出演
                          </div>

                          <div className="info-career-description">
                            • NHK「おはよう日本」へ特集
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="divider" style={{
              marginTop: showCareerDetails ? '5px' : '20px'
            }} />

            {/* Common Stock */}
            <div className='info-section-container'>
              <div className='info-key-section'>
                <div className="label-jp">資本金</div>
                <div className="label-en">Common Stock</div>
              </div>

              <span className="vertical-divider" />

              <div className='info-value-section' style={{ alignSelf: 'center' }}>
                <div className="label-jp">¥10,000-</div>

              </div>
            </div>

            <div className="divider" />

            {/* Business */}
            <div className='info-section-container'>
              <div className='info-key-section'>
                <div className="label-jp">事業内容</div>
                <div className="label-en">Business</div>
              </div>

              <span className="vertical-divider" />

              <div className='info-value-section'>
                <ul className="bullet-list font-inter">
                  <li className='font-inter'>
                    CarVibes事業
                    <ul className="sub-bullet-list">
                      <li>CarVibesの運営・企画・開発</li>
                    </ul>
                  </li>
                  <li className='font-inter'>
                    コンサルティング事業
                    <ul className="sub-bullet-list">
                      <li>コンサル支援（事業戦略含む）</li>
                      <li>事業再建（経営再建、事業再生含む）</li>
                      <li>新規事業支援（企業内の新規事業創出支援）</li>
                      <li>デジタルマーケティング支援　など他多数</li>
                    </ul>
                  </li>
                  <li className='font-inter'>
                    その他
                    <ul className="sub-bullet-list">
                      <li>コーチング、顧問、メディア出演・講演活動など他多数</li>
                    </ul>
                  </li>
                </ul>
              </div>
            </div>

            <div className="divider" />

            {/* Intellectual Property */}
            <div className='info-section-container'>
              <div className='info-key-section'>
                <div className="label-jp">IP・知的財産権</div>
                <div className="label-en">Intellectual Property</div>
              </div>

              <span className="vertical-divider" />

              <div className='info-value-section' style={{ alignSelf: 'center' }}>
                <div className="label-jp">
                  <ul className="bullet-list font-inter">
                    <li className='font-inter'>
                      商標登録証: 登録第 6765069号
                    </li>
                    <li className='font-inter'>
                      (EU/UK) 国際登録番号: 1782830号
                    </li>
                  </ul>
                  <p className='ip-description-content font-inter'>
                    ※日本国及び、EU・UK(英国)において、CarVibesは商標登録されています。
                  </p>
                </div>

              </div>
            </div>

            <div className="divider" />

            {/* License */}
            <div className='info-section-container'>
              <div className='info-key-section'>
                <div className="label-jp">各種届出</div>
                <div className="label-en">License</div>
              </div>

              <span className="vertical-divider" />

              <div className='info-value-section' style={{ alignSelf: 'center' }}>
                <div className="label-jp">
                  <ul className="bullet-list font-inter">
                    <li>
                      電気通信事業の届出番号: A-06-21911
                    </li>
                  </ul>
                </div>

              </div>
            </div>

            <div className="divider" />

          </div>
        </div>
      </div>

      <footer className={"footer"}>
        <div className={"container4"}>
          <div className={"container5"}>
            <span className="copyright">
              <Link to={`/terms-of-use`}>
                {translatedTexts[7]}
              </Link>
            </span>
            <span className="copyright">
              <Link to={`/privacy-policy`}>
                {translatedTexts[8]}
              </Link>
            </span>
            <span className="copyright">
              <a
                href="https://docs.google.com/forms/d/1v91vwEmFwN0idylrob7X4jumzUq_FWKIGL76HIe_tzs/formrestricted"
                target="_blank"
                rel="noopener noreferrer"
              >
                {translatedTexts[9]}
              </a>
            </span>

            <span className="copyright">
              <Link to={`/company`}>
                {translatedTexts[10]}
              </Link>

            </span>
          </div>
        </div>
        <span className="copyright">
          COPYRIGHT 2024 T&amp;F ASSET MANAGEMENT INC. ALL RIGHTS RESERVED.
        </span>
      </footer>

    </div>
  );
};

export default CompanyPage;
