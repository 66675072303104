import type from "./typeActions";

const initialState = {
  session: true,
  loggedin: false,
  carvibess: [],
  carvibes: null,
  account: null,
  user: null,
  bookmarks: [],
  recent_carvibess: [],
  lists: [],
  list: null,
  trends: [],
  result: [],
  tagCarvibess: [],
  followers: [],
  following: [],
  resultUsers: [],
  suggestions: [],
  top: "-100px",
  msg: "",
  conversations: null,
  conversation: null,
  error: false,
  reportedUsers: [],
  users: [],
  logs: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case type.SET_STATE:
      return { ...state, ...action.payload };

    case type.ERROR:
      // message.error(action.payload.msg? action.payload.msg : action.payload == 'Unauthorized' ? 'You need to sign in' : 'error');
      return { ...state, loading: false, error: true, msg: action.payload.msg };

    case type.RESET_ERROR:
      return { ...state, error: false, msg: "" };

    case type.LOGIN:
      // localStorage.setItem("carvibestoken", action.payload.token)
      return {
        ...state,
        ...action.payload,
        loggedin: true,
        loading: false,
        error: false,
      };

    case type.ADMIN_LOGIN:
      localStorage.setItem("carvibestoken", action.payload.token)
      return {
        ...state,
        ...action.payload,
        loggedin: true,
        loading: false,
        error: false,
      };

    case type.REGISTER:
      setTimeout(() => {
        action.data.func();
      }, 250);
      return { ...state, ...action.payload, loading: false, error: false };

    case type.CARVIBES: {
      let recentT = state.carvibess;
      let s_carvibes = state.carvibes;
      if (action.payload.carvibes && action.payload.carvibes.parent === null) {
        recentT.unshift(action.payload.carvibes);
      }
      if (s_carvibes && s_carvibes._id === action.data.parent) {
        s_carvibes.replies.unshift(action.payload.carvibes);
      }
      return { ...state, loading: false, error: false };
    }

    case type.EDIT_CARVIBES: {
      const updatedCarvibes = action.payload;
      const updatedCarvibess = state.carvibess.map((carvibes) =>
        carvibes._id === updatedCarvibes._id ? updatedCarvibes : carvibes
      );

      let s_carvibes = state.carvibes;
      if (s_carvibes && s_carvibes._id === updatedCarvibes._id) {
        s_carvibes = updatedCarvibes;
      }

      const newState = {
        ...state,
        carvibess: updatedCarvibess,
        carvibes: s_carvibes,
        edited: true,
      };

      return {
        ...newState,
        loading: false,
        error: false,
      };
    }

    case type.LIKE_CARVIBES:
      let account_likes = state.account;
      let carvibes_likes = state.carvibess;
      let user_likes = state.user;
      let Scarvibes_likes = state.carvibes;
      if (action.payload.msg === "liked") {
        if (Scarvibes_likes) {
          Scarvibes_likes.likes.push(action.data.id);
        }

        account_likes.likes.push(action.data.id);
        carvibes_likes.length &&
          carvibes_likes
            .find((x) => x._id === action.data.id)
            .likes.push(account_likes._id);

        if (action.data.dest === "profile") {
          user_likes.carvibess
            .find((x) => x._id === action.data.id)
            .likes.push(action.data.id);
          user_likes.likes = user_likes.carvibess
            .filter((x) => x._id === action.data.id)
            .concat(user_likes.likes);
        }
      } else if (action.payload.msg === "unliked") {
        if (Scarvibes_likes) {
          Scarvibes_likes.likes = Scarvibes_likes.likes.filter((x) => {
            return x !== action.data.id;
          });
        }

        carvibes_likes.length &&
          carvibes_likes.find((x) => x._id === action.data.id).likes.pop();
        let likeIndex = account_likes.likes.indexOf(action.data.id);
        likeIndex > -1 && account_likes.likes.splice(likeIndex, 1);

        if (action.data.dest === "profile") {
          user_likes.carvibess
            .find((x) => x._id === action.data.id)
            .likes.pop();
          user_likes.likes = user_likes.likes.filter((x) => {
            return x._id !== action.data.id;
          });
        }
      }
      return {
        ...state,
        ...{ account: account_likes },
        ...{ carvibess: carvibes_likes },
        ...{ user: user_likes },
        ...{ carvibes: Scarvibes_likes },
      };

    case type.GET_CARVIBESS:
      return { ...state, ...action.payload, loading: false, error: false };

    case type.GET_CARVIBES:
      return { ...state, ...action.payload, loading: false, error: false };

    case type.GET_ACCOUNT:
      return { ...state, ...action.payload };

    case type.BOOKMARK:
      let account_bookmarks = state.account;
      if (action.payload.msg === "bookmarked") {
        account_bookmarks.bookmarks.push(action.data.id);
      } else if (action.payload.msg === "removed from bookmarks") {
        let bookIndex = account_bookmarks.bookmarks.indexOf(action.data.id);
        bookIndex > -1 && account_bookmarks.bookmarks.splice(bookIndex, 1);
      }
      return { ...state, ...{ account: account_bookmarks } };

    case type.GET_USER:
      return { ...state, ...action.payload, loading: false, error: false };

    case type.GET_BOOKMARKS:
      return { ...state, ...action.payload };

    case type.UPDATE_USER:
      Object.keys(action.data).forEach((key) =>
        action.data[key] === "" || action.data[key] === undefined
          ? delete action.data[key]
          : null
      );

      let updateUser = { ...state.user, ...action.data };

      // Update account
      let updateAccount = { ...state.account, ...action.data };

      // Update carvibess
      let updateCarvibess = state.user.carvibess.map(carvibes => ({
        ...carvibes,
        user: {
          ...carvibes.user,
          profileImg: action.data.profileImg || carvibes.user.profileImg
        }
      }));

      const finalobj = {
        ...state,
        user: { ...updateUser, carvibess: updateCarvibess },
        account: updateAccount
      };

      return finalobj;

    case type.DELETE_CARVIBES:
      let userCarvibessD = state.user;
      let homeCarvibessD = state.carvibess;
      let singleCarvibes = state.carvibes;
      if (userCarvibessD) {
        userCarvibessD.carvibess =
          userCarvibessD &&
          userCarvibessD.carvibess.filter((x) => {
            return x._id !== action.data;
          });
      }
      if (singleCarvibes && action.data === singleCarvibes._id) {
        window.location.replace("/home");
        singleCarvibes = null;
      }
      homeCarvibessD = homeCarvibessD.filter((x) => {
        return x._id !== action.data;
      });
      return {
        ...state,
        ...{ user: userCarvibessD },
        ...{ carvibess: homeCarvibessD },
        ...{ carvibes: singleCarvibes },
      };

    case type.FOLLOW_USER:
      let accountF = state.account;
      let user_followers = state.followers;
      if (action.payload.msg === "follow") {
        accountF.following.push(action.data);
      } else if (action.payload.msg === "unfollow") {
        accountF.following = accountF.following.filter((f) => {
          return f !== action.data;
        });
        user_followers = user_followers.filter((f) => {
          return f._id !== action.data;
        });
      }
      return {
        ...state,
        ...{ account: accountF },
        ...{ followers: user_followers },
      };

    case type.GET_TREND:
      return { ...state, ...action.payload };

    case type.SEARCH:
      return { ...state, ...action.payload };

    case type.TREND_CARVIBESS:
      let t_carvibess = action?.payload?.tagCarvibess?.carvibess;
      return { ...state, ...{ tagCarvibess: t_carvibess } };

    case type.GET_FOLLOWERS:
      return { ...state, ...action.payload };

    case type.GET_FOLLOWING:
      return { ...state, ...action.payload };

    case type.SEARCH_USERS:
      return { ...state, ...action.payload };

    case type.WHO_TO_FOLLOW:
      return { ...state, ...action.payload };

    case type.GET_CONVERSATIONS:
      return { ...state, ...action.payload };

    case type.START_CHAT:
      setTimeout(() => {
        action.data.func();
      }, 250);
      return { ...state, ...action.payload };

    case type.GET_SINGLE_CONVERSATION:
      setTimeout(() => {
        action.data.func(action.payload.conversation.messages);
      }, 250);
      return { ...state, ...action.payload };
    case type.REPORT:
      return { ...state, ...action.payload };
    case type.GET_REPORTS:
      return { ...state, ...action.payload, loading: false };
    case type.PROCESS_REPORT:
      return { ...state, ...action.payload };
    case type.DELETE_REPORT:
      return { ...state, ...action.payload };
    case type.UPDATE_REPORT:
      return { ...state, ...action.payload };
    case type.GET_USERS:
      return { ...state, ...action.payload, loading: false };
    case type.DELETE_USER:
      return { ...state, ...action.payload };
    case type.WARN_USER:
      return { ...state, ...action.payload };
    case type.GET_LOGS:
      return { ...state, ...action.payload, loading: false };
    case type.SEND_NOTIFICATION:
      return { ...state, ...action.payload, loading: false };
    default:
      return state;
  }
};

export { initialState, reducer };
