import React, { useEffect, useState, useContext, useRef } from 'react';
import { StoreContext } from '../../store/store';
import { ICON_ARROWBACK } from '../../Icons';
import axios from '../../axiosLib';
import { API_URL } from '../../config';
import { TagsInput } from 'react-tag-input-component';
import CarvibesCard from '../CarvibesCard';
import CategorySelector from '../CategorySelector';
import { compressImage, convertFromHeic, supportedImageFiletypes } from '../../utils/common';
import UserAvatar from '../UserAvatar';
import { ImSpinner2 } from "react-icons/im";
import './style.scss';

const EditCarvibesModal = ({ postData, onClose }) => {
  const { state, actions } = useContext(StoreContext);
  const { account } = state;

  const [isOpen, setIsOpen] = useState(true);
  const [popupState, setPopupState] = useState(0);
  const [carvibesText, setCarvibesText] = useState('');
  const [carvibesImages, setCarvibesImages] = useState([]);
  const [imageLoaded, setImageLoaded] = useState(false);
  const [selectedCategories, setCategories] = useState([]);
  const [hashtags, setHashtags] = useState([]);
  const [submitted, setSubmitted] = useState(false);
  const [openOkModal, setOpenOkModal] = useState(false);
  const [originalPostData, setOriginalPostData] = useState(null);
  const [isUploading, setIsUploading] = useState(false);

  const texts = [
    'ダッシュボード',
    '検索',
    '投稿',
    'マイページ',
    '設定',
    '投稿しました',
    '投稿を編集',
    'プレビュー',
    '次へ',
    '投稿する',
    'キャプション',
    '愛車について自由に記録してください。',
    'タグ',
    'カテゴリ（任意）',
  ];

  const carvibesT = useRef('');

  useEffect(() => {
    if (postData) {
      setCarvibesText(postData.description || '');
      carvibesT.current = postData.description || '';
      setCarvibesImages(postData.images || []);
      setCategories(postData.categories || []);
      setHashtags(postData.hashtags || []);
      setOriginalPostData(postData);
    }
  }, [postData]);

  const toggleModal = () => {
    setIsOpen(false);
    setPopupState(0);
    setCarvibesText('');
    setCarvibesImages([]);
    setCategories([]);
    setHashtags([]);
    setSubmitted(false);
    if (onClose) onClose();
  };

  const handleModalClick = (e) => {
    e.stopPropagation();
  };

  const handleChange = (evt) => {
    carvibesT.current = evt.target.value;
    setCarvibesText(carvibesT.current);
  };

  const canSubmit =
    carvibesText.trim().length > 0 &&
    hashtags.length > 0 &&
    carvibesImages.length > 0;

  const hasChanges = () => {
    if (!originalPostData) return false;

    return (
      carvibesText !== originalPostData.description ||
      !arraysEqual(carvibesImages, originalPostData.images) ||
      !arraysEqual(selectedCategories, originalPostData.categories) ||
      !arraysEqual(hashtags, originalPostData.hashtags)
    );
  };

  const arraysEqual = (a, b) => {
    if (a === b) return true;
    if (a == null || b == null) return false;
    if (a.length !== b.length) return false;
    for (let i = 0; i < a.length; i++) {
      if (a[i] !== b[i]) return false;
    }
    return true;
  };

  const submitCarvibes = () => {
    if (!canSubmit && isUploading) {
      return;
    }
    const values = {
      description: carvibesText,
      images: carvibesImages,
      hashtags: hashtags,
      categories: selectedCategories,
    };

    if (postData && postData.id) {
      const updateData = {
        id: postData.id,
        values: values
      };
      actions.editCarvibes(updateData);
    }

    toggleModal();
    setOpenOkModal(true);
  };

  const onchangeImage = async (e) => {
    if (carvibesImages && carvibesImages.length >= 5) return;
    const files = e.target.files;
    if (files && files.length > 5) return;

    uploadImage(files);
  };

  const uploadImage = async (files) => {
    const compressedImages = [];
    setIsUploading(true)
    for (let i = 0; i < files.length; i++) {
      const file = files[i];

      if (file.type === 'image/heic' || file.type === 'image/heif') {
        const converted = await convertFromHeic(file, 0.1);
        const compressed = await compressImage(converted, 0.2)
        compressedImages.push(compressed)
      } else {
        const img = await compressImage(file)
        compressedImages.push(img)
      }
    }

    // set the image here instead of after uplaod
    let bodyFormData = new FormData();
    for (let i = 0; i < compressedImages.length; i++) {
      const img = compressedImages[i];
      bodyFormData.append("images", img);
    }

    try {
      const res = await axios.post(`${API_URL}/carvibes/upload`, bodyFormData);
      let imgUrls = res.data.imageUrls;
      let new_arr = carvibesImages ? [...carvibesImages, ...imgUrls] : imgUrls;
      setCarvibesImages(new_arr);

      const imageInput = document.getElementById("image_1");
      if (imageInput) {
        imageInput.value = "";
      }
      setIsUploading(false)
    } catch (err) {
      console.log(err);
      alert("Error uploading image");
      setIsUploading(false)
    }
  };

  const removeImage = (index) => {
    let new_arr = [...carvibesImages];
    new_arr.splice(index, 1);
    setCarvibesImages(new_arr);
    setImageLoaded(false);
  };

  function modal() {
    return (
      <div
        style={{
          // Full screen
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          zIndex: 1000,
        }}
      >
        <div
          style={{
            // Transparent background, full screen
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(0,0,0,0.5)',
          }}
        ></div>
        <div
          style={{
            // Modal content
            position: 'fixed',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%,-50%)',
            backgroundColor: 'white',
            padding: '20px',
            borderRadius: '10px',
            width: '300px',
            textAlign: 'center',
            height: '150px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
          }}
        >
          <p>{texts[5]}</p>
          <div
            onClick={() => setOpenOkModal(false)}
            style={{
              color: 'white',
              padding: '10px 20px',
              background:
                'linear-gradient(89.94deg, #395A37 -0.36%, #2B4773 99.95%)',
              boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.122)',
              borderRadius: '4px',
              cursor: 'pointer',
            }}
          >
            完了
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      {isOpen && (
        <div onClick={toggleModal} className="modal-edit">
          <div onClick={handleModalClick} className="edit-carvibes-modal-content">
            <div className="modal-header">
              <div className="modal-closeIcon">
                <div
                  onClick={() => {
                    if (popupState === 0) toggleModal();
                    else setPopupState(0);
                  }}
                  className="modal-closeIcon-wrap"
                >
                  <ICON_ARROWBACK />
                </div>
              </div>
              <p className="modal-title">
                {popupState === 0 ? texts[6] : texts[7]}
              </p>
              <p
                className="modal-action carvibes-btn-side carvibes-btn-active"
                style={{
                  color: `${(!canSubmit || !hasChanges()) ? 'transparent' : '#b6322e'}`
                }}
                onClick={() => {
                  setSubmitted(true);
                  if (!canSubmit || !hasChanges()) {
                    return;
                  }
                  if (popupState === 0) {
                    setPopupState(1);
                  } else {
                    submitCarvibes();
                  }
                }}
              >
                {popupState === 0 ? texts[8] : texts[9]}
              </p>
            </div>
            <div style={{ marginTop: '5px' }} className="modal-body">
              {popupState === 0 && (
                <div
                  style={{ position: 'relative' }}
                  className="Carvibes-input-wrapper"
                >
                  <div className="Carvibes-profile-wrapper">
                    <div>
                      <UserAvatar user={account} />
                    </div>
                  </div>
                  <div className="inner-image-root">
                    {carvibesImages &&
                      carvibesImages.map((carvibesImage, index) => {
                        return (
                          <div
                            className="inner-image-box"
                            key={`carvibesImage_${index}`}
                          >
                            <img
                              onLoad={() => setImageLoaded(true)}
                              className="carvibes-upload-image"
                              src={carvibesImage}
                              alt="carvibesImage"
                            />
                            {imageLoaded && (
                              <span
                                onClick={() => removeImage(index)}
                                className="cancel-image"
                              >
                                x
                              </span>
                            )}
                          </div>
                        );
                      })}
                    {isUploading ?
                      <div
                        className="inner-image-box"
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      >
                        <ImSpinner2
                          className="spinner"
                          size={20}
                          style={{ color: "black", animation: "spin 1s linear infinite" }}
                        />
                      </div> : null}
                    <div
                      style={{ marginLeft: '-10px' }}
                      className="input-attach-wrapper"
                    >
                      <div
                        style={{
                          height: '80px',
                          width: '80px',
                          display: 'block',
                          border: '1px dotted grey',
                          textAlign: 'center',
                          alignContent: 'center',
                          justifyContent: 'center',
                          alignItems: 'center',
                          borderRadius: '10px',
                          cursor: 'pointer',
                          lineHeight: '75px',
                          marginTop: '10px',
                          marginBottom: '20px',
                          marginLeft: '10px',
                        }}
                      >
                        +
                        <input
                          title=" "
                          style={{ opacity: '0' }}
                          type="file"
                          multiple={true}
                          accept={supportedImageFiletypes.join(', ')}
                          onChange={(e) => onchangeImage(e)}
                        />
                      </div>
                    </div>
                  </div>
                  {submitted && (!carvibesImages || !carvibesImages.length) && (
                    <p style={{ color: 'red' }}>必須項目です</p>
                  )}
                  <div className="Carvibes-input-side">
                    <p
                      style={{
                        marginBottom: '5px',
                        color: '#3E3E3E',
                        fontSize: '0.85em',
                      }}
                    >
                      {texts[10]}
                    </p>
                    <div className="inner-input-box">
                      <textarea
                        id="carvibesPop1"
                        style={{
                          minHeight: '130px',
                          minWidth: '100px',
                          width: '100%',
                          fontSize: '16px',
                        }}
                        className={
                          carvibesText.length ? 'carvibes-input-active' : null
                        }
                        value={carvibesText}
                        placeholder={texts[11]}
                        onChange={handleChange}
                      />
                    </div>

                    {submitted && !carvibesText && (
                      <p style={{ color: 'red', marginTop: '10px' }}>
                        必須項目です
                      </p>
                    )}
                    <div className="inner-tags-box">
                      <p
                        style={{
                          marginBottom: '-15px',
                          marginTop: '15px',
                          color: '#3E3E3E',
                          fontSize: '0.85em',
                        }}
                      >
                        {texts[12]}
                      </p>
                      <TagsInput
                        value={hashtags}
                        onChange={setHashtags}
                        classNames={{
                          input:
                            hashtags.length > 0
                              ? 'tags-input-narrow'
                              : 'tags-input-wide',
                        }}
                        name="tags"
                        disabled={isUploading}
                        placeHolder={
                          hashtags.length > 0
                            ? ''
                            : '入力したいキーワードを入力してEnterキーを押してください'
                        }
                      />
                      {submitted && !hashtags.length && (
                        <p style={{ color: 'red' }}>必須項目です</p>
                      )}
                    </div>
                    <CategorySelector
                      title={texts[13]}
                      selectedCategories={selectedCategories}
                      onChange={setCategories}
                    />
                  </div>
                </div>
              )}
              {popupState === 1 && (
                <div
                  style={{
                    display: 'block',
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginBottom: '40px',
                  }}
                >
                  <CarvibesCard
                    showFollow={true}
                    carvibesUpdated={(id) => { }}
                    recarvibes={{ description: '' }}
                    actions={false}
                    username={''}
                    name={''}
                    parent={''}
                    key={'____carvibescard____'}
                    id={postData ? postData.id : ''}
                    user={account}
                    createdAt={new Date()}
                    description={carvibesText}
                    images={carvibesImages}
                    replies={[]}
                    likes={[]}
                  />
                </div>
              )}
            </div>
          </div>
        </div >
      )}
      {openOkModal && modal()}
    </>
  );
};

export default EditCarvibesModal;