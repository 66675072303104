import heic2any from "heic2any";
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

export const supportedImageFiletypes = [
  "image/jpeg",
  "image/png",
  "image/gif",
  "image/bmp",
  "image/jpg",
  "image/heic",
  "image/heif"
];

export const getDatetimeFormat = (countryCode) => {
  return countryCode.toLowerCase() === "jp"
    ? "YYYY/MM/DD hh:mm"
    : "DD/MM/YYYY HH:mm";
};

export const timeDisplay = (utcTimestamp) => {
  dayjs.extend(utc);
  dayjs.extend(timezone);
  
  return dayjs.utc(utcTimestamp).local().format('YYYY/MM/DD HH:mm');
};

export const checkIfTextIsJa = (text) => {
  // Regular expression to match Japanese characters
  const jpRegex = /[\u3000-\u303f\u3040-\u309f\u30a0-\u30ff\uff00-\uff9f\u4e00-\u9faf\u3400-\u4dbf]/;
  return jpRegex.test(text);
}

export const getLangCodeFromLangString = (language) => {
  const mapping = {
    US: 'en-US',
    GB: 'en-GB',
    ES: 'es',
    FR: 'fr',
    DE: 'de',
    IT: 'it',
    PT: 'pt',
    CN: 'zh',
    JP: 'ja'
  };

  return mapping[language] || 'ja';
}

export const getFlagCodeFromLangCode = (langCode) => {
  const mapping = {
    'en-US': 'US',
    'en-GB': 'GB',
    'es': 'ES',
    'fr': 'FR',
    'de': 'DE',
    'it': 'IT',
    'pt': 'PT',
    'zh': 'CN',
    'ja': 'JP'
  };

  return mapping[langCode] || 'JP';
}

export const extractLinks = (text) => {
  const linkRegex = /(https?:\/\/[^\s]+)/g;
  return text.match(linkRegex) || [];
};

export const replaceLinksWithPlaceholders = (text, links) => {
  let modifiedText = text;
  const placeholders = [];

  links.forEach((link, index) => {
    const placeholder = `__LINK_${index}__`;
    modifiedText = modifiedText.replace(link, placeholder);
    placeholders.push(placeholder);
  });

  return { modifiedText, placeholders };
};

export const replacePlaceholdersWithLinks = (text, links, placeholders) => {
  let result = text;
  placeholders.forEach((placeholder, index) => {
    result = result.replace(placeholder, links[index]);
  });

  console.log(result);
  return result;
};

export const compressImage = (file, quality = 0.4, maxDimension = 4096) => {
  return new Promise(async (resolve, reject) => {
    const extension = file.name.split('.').pop().toLowerCase();
    const supportedTypes = ['png', 'jpeg', 'jpg'];

    if (!supportedTypes.includes(extension)) {
      resolve(file);
      return;
    }

    const img = new Image();
    img.src = URL.createObjectURL(file);
    img.onload = () => {
      let width = img.width;
      let height = img.height;

      // Calculate new dimensions if the image is too large
      if (width > maxDimension || height > maxDimension) {
        if (width > height) {
          height *= maxDimension / width;
          width = maxDimension;
        } else {
          width *= maxDimension / height;
          height = maxDimension;
        }
      }

      const canvas = document.createElement("canvas");
      canvas.width = width;
      canvas.height = height;

      const ctx = canvas.getContext("2d");
      ctx.drawImage(img, 0, 0, width, height);

      canvas.toBlob(
        (blob) => {
          const baseName = file.name.slice(0, -(extension.length + 1));
          const newFileName = `${baseName}.${extension}`;

          const compressedFile = new File([blob], newFileName, {
            type: file.type,
            lastModified: Date.now(),
          });
          resolve(compressedFile);
        },
        file.type,
        quality
      );
    };
    img.onerror = reject;
  });
};

export const convertFromHeic = async (file, quality = 0.4) => {
  const convertedBlob = await heic2any({
    blob: file,
    toType: 'image/jpeg',
    quality: quality,
  });

  // Return the new converted JPEG file
  const convertedFile = new File([convertedBlob], file.name.replace(/\.[^.]+$/, '.jpg'), {
    type: 'image/jpeg',
  });

  return convertedFile

}