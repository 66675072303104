import React from 'react';

const TableItem = ({ title, width, fontSize, paddingRight, backgroundColor }) => {
    return (
        <div style={{
            width: width,
            paddingRight: paddingRight,
            backgroundColor: backgroundColor
        }}>
            <p className="admin-report-description" style={{
                fontSize: fontSize,
            }}>
                {title}
            </p>
        </div>
    );
};

export default TableItem;