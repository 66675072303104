import React, { useContext, useState, useRef, useEffect } from "react";
import "./style.scss";
import moment from "moment";
import { StoreContext } from "../../store/store";
import { Link, withRouter } from "react-router-dom";
import Linkify from 'linkify-react';
import {
  ICON_REPLY,
  ICON_HEART,
  ICON_CLOSE,
  ICON_ARROWBACK,
  ICON_PLUS,
  ICON_MINUS,
} from "../../Icons";
import axios from "../../axiosLib";
import { API_URL } from "../../config";
import ContentEditable from "react-contenteditable";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import dayjs from "dayjs";
import { useLocalization } from "../../hooks/useLocalization";
import FollowButton from "../Profile/FollowButton";
import { getDatetimeFormat, checkIfTextIsJa, getLangCodeFromLangString, timeDisplay } from "../../utils/common";
import UserAvatar from "../UserAvatar";
import { IoIosFlag } from "react-icons/io";
import { BsInfoCircle } from "react-icons/bs";
import InformModal from '../InformModal';
import Tooltip from '../Tooltip';
import EditCarvibesModal from '../EditCarvibes';

const SCREEN_TEXT = ["投稿する", "いいね！が付いています", "返事"];

const CarvibesCard = React.memo(function CarvibesCard(props) {
  const { state, actions } = useContext(StoreContext);
  const { account, session, user } = state;
  const { countryCode, translatedTexts } = useLocalization(SCREEN_TEXT);

  const [translatedDescription, setTranslatedDescription] = useState(props.description);
  const [likedByText, setLikedByText] = useState(translatedTexts[1]);
  const [translationAlert, setTranslationAlert] = useState("");
  const [deleteCarvibesId, setDeleteCarvibesId] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [popUpImageSrc, setPopUpImageSrc] = useState("");
  const [likesOpen, setLikesOpen] = useState(false);
  const [replyText, setReplyText] = useState("");
  const [replyImage, setReplyImg] = useState(null);
  const [imageLoaded, setImageLoaded] = useState(false);
  const [parent, setParent] = useState(false);
  const [styleBody, setStyleBody] = useState(false);
  const [showMore, setShowMore] = useState(false);
  const [composing, setComposing] = useState(false);
  const [reported, setReported] = useState(props.reported);
  const [reportModalOpen, setReportModalOpen] = useState(false);
  const [selectedCarvibesForReport, setSelectedCarvibesForReport] = useState("");
  const [editCarvibesData, setEditCarvibesData] = useState(null);
  const MAX_STRING_LENGTH = 140;

  let info;
  const likeCarvibes = (e, id) => {
    e.stopPropagation();
    if (!session) {
      actions.alert("Please Sign In");
      return;
    }
    if (props.history.location.pathname.slice(1, 5) === "prof") {
      info = { dest: "profile", id };
    } else {
      info = { id };
    }
    actions.likeCarvibes(info);
    setTimeout(() => {
      actions.verifyToken("get account");
    }, 1000);
  };

  const bookmarkCarvibes = (e, id) => {
    e.stopPropagation();
    if (!session) {
      actions.alert("Please Sign In");
      return;
    }
    if (props.history.location.pathname.slice(1, 5) === "prof") {
      info = { dest: "profile", id };
    } else {
      info = { id };
    }
    actions.bookmarkCarvibes(info);
  };

  const onReport = (e, id) => {
    e.stopPropagation();

    setReportModalOpen(true);
    setSelectedCarvibesForReport(id);
  }

  const reportCarvibes = () => {
    const id = selectedCarvibesForReport;

    if (!session) {
      actions.alert("Please Sign In");
      return;
    }

    if (!id) {
      return;
    }
    if (props.history.location.pathname.slice(1, 5) === "prof") {
      info = { dest: "profile", id };
    } else {
      info = { id, user: props.user._id };
    }
    actions.reportCarvibes(info);
    setReported(true);

    setReportModalOpen(false);
    setSelectedCarvibesForReport("");
  };

  const recarvibes = (e, id, recarvibesId) => {
    e.stopPropagation();
    if (!session) {
      actions.alert("Please Sign In");
      return;
    }
    if (props.history.location.pathname.slice(1, 5) === "prof") {
      info = { dest: "profile", id, recarvibesId };
    } else {
      info = { id, recarvibesId };
    }
    actions.recarvibes(info);
  };

  const deleteCarvibes = (e, id) => {
    e.stopPropagation();
    actions.deleteCarvibes(id);
  };

  const goToCarvibes = (id) => {
    if (props.replyTo) {
      actions.getCarvibes(id);
    }
    props.history.push(`/carvibes/${props.user._id}/${id}`);
  };

  const goToReply = (e, id) => {
    e.stopPropagation();
    if (props.replyTo) {
      actions.getCarvibes(id);
    }
    props.history.push(`/carvibes/${props.user._id}/${id}`);
  };

  const uploadImage = (file) => {
    let bodyFormData = new FormData();
    bodyFormData.append("image", file);
    axios
      .post(`${API_URL}/carvibes/upload`, bodyFormData, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("carvibestoken")}`,
        },
      })
      .then((res) => {
        setReplyImg(res.data.imageUrl);
      })
      .catch((err) => {
        console.log(err)
        alert("error uploading image")
      });
  };

  const onchangeImage = () => {
    let file = document.getElementById("img").files[0];
    uploadImage(file);
  };

  const removeImage = () => {
    document.getElementById("img").value = "";
    setReplyImg(null);
    setImageLoaded(false);
  };

  const toggleModal = (e, type) => {
    if (e) {
      e.stopPropagation();
    }
    if (!session) {
      actions.alert("Please Sign In");
      return;
    }
    setStyleBody(!styleBody);
    if (type === "parent") {
      setParent(true);
    } else {
      setParent(false);
    }
    setTimeout(() => {
      setModalOpen(!modalOpen);
      if (!modalOpen) {
        document.documentElement.classList.add("scroll-disabled");
      } else {
        document.documentElement.classList.remove("scroll-disabled");
      }
    }, 20);
  };

  const handleModalClick = (e) => {
    e.stopPropagation();
  };

  const carvibesT = useRef("");
  const composition = useRef("");

  const handleChange = (evt) => {
    var preservedText = evt.target.value.replace(/(?:\r\n|\r|\n)/g, "<br>");
    composition.current = preservedText;
    if (composing) return;
    carvibesT.current = composition.current;
    setReplyText(carvibesT.current);
  };

  const handleTranslateDescription = async (text) => {
    const lang = localStorage.getItem("lang");
    const langCode = getLangCodeFromLangString(lang);

    // Extracting links, <br /> elements, and \n
    const elements = [];
    const placeholder = "\uFFFC";

    const regex = /(\s*)(<a\b[^>]*>(.*?)<\/a>|https?:\/\/[^\s]+|<br\s*\/?>|\n)(\s*)/gi;
    let cleanText = text.replace(regex, (match, leadingSpace, element, content, trailingSpace) => {
      elements.push(leadingSpace + element + trailingSpace);
      return placeholder;
    });

    if (!checkIfTextIsJa(cleanText) || !checkIfTextIsJa(likedByText)) {
      setTranslationAlert('Text is not in Japanese');
      return;
    }

    if (langCode === 'ja') {
      setTranslationAlert('母国語が日本語のため翻訳できません.');
      return;
    }

    // set language if its different from the current one.
    if (user.nativeLang !== langCode && lang) {
      actions.updateUser({
        nativeLang: langCode,
        _id: account._id,
      });
    }

    const values = {
      content: cleanText.trim(),
      lang: langCode,
    };

    // translate the likedBy string separately
    let translatedLikedByText;
    try {
      const response = await axios.post(`${API_URL}/translate/liked-by`, { lang: langCode });
      translatedLikedByText = response.data.translation;
    } catch (err) {
      console.error(`Translation error: ${JSON.stringify(err)}`);
      setTranslationAlert('Translation error');
    }

    try {
      const response = await axios.post(`${API_URL}/translate/carvibes`, values);
      let translatedDescription = response.data.translation;

      // Reinsert links, <br />, and \n back into the translated text
      translatedDescription = translatedDescription.replace(new RegExp(placeholder, 'g'), () => elements.shift());

      setLikedByText(translatedLikedByText)
      setTranslatedDescription(translatedDescription);
    } catch (err) {
      console.error(`Translation error: ${JSON.stringify(err)}`);
      setTranslationAlert('Translation error');
    }

  };

  const isInitialMount = useRef(true);

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
    } else {
      document.getElementsByTagName("body")[0].style.cssText =
        styleBody && "overflow-y: hidden; margin-right: 17px";
    }
  }, [styleBody]);

  useEffect(
    () => () => (document.getElementsByTagName("body")[0].style.cssText = ""),
    []
  );

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
    } else if (document.getElementById("replyBox")) {
      document.getElementById("replyBox").focus();
    }
  }, [modalOpen]);

  const replyCarvibes = (type) => {
    if (!replyText.length) {
      return;
    }
    let hashtags = replyText.match(/#(\w+)/g);
    const values = {
      description: replyText,
      images: [replyImage],
      parent:
        type === "parent"
          ? props.parent._id
          : type === "recarvibes"
            ? props.recarvibes._id
            : props.id,
      hashtags,
    };
    if (values.parent.length === 0) return;
    if (!session) {
      actions.alert("Please Sign In");
      return;
    }
    toggleModal();

    props.carvibesUpdated(values.parent);
    actions.carvibes(values);
    carvibesT.current = "";
    setReplyText("");
    setReplyImg(null);
    actions.alert("Carvibes sent!");
  };

  const goToUser = (e, userid) => {
    e.stopPropagation();
    props.history.push(`/profile/${userid}`);
  };

  moment.updateLocale("en", {
    relativeTime: {
      future: "in %s",
      past: "%s ago",
      s: "few seconds ago",
      ss: "%ss",
      m: "1m",
      mm: "%dm",
      h: "1h",
      hh: "%dh",
      d: "a day",
      dd: "%dd",
      M: "a month",
      MM: "%dM",
      y: "a year",
      yy: "%dY",
    },
  });

  useEffect(() => {
    if (typeof window != "undefined") {
      if (popUpImageSrc !== "") {
        document.documentElement.classList.add("scroll-disabled");
      } else {
        document.documentElement.classList.remove("scroll-disabled");
      }
    }
  }, [popUpImageSrc]);

  if (!props.parent && !props.user) {
    return null;
  }

  return (
    <div className="Carvibes-card-root">
      {props.parent ? (
        <div
          onClick={(e) => toggleModal(e)}
          key={props.parent._id}
          style={{ borderBottom: "0px" }}
          className="Carvibes-card-wrapper"
        >
          <div
            style={{ display: "flex", flexDirection: "column" }}
            className="card-userPic-wrapper"
          >
            {props.parent.parent ? (
              <div style={{ marginTop: "17px" }}></div>
            ) : null}
            <Link
              onClick={(e) => e.stopPropagation()}
              to={`/profile/${props.parent.user._id}`}
            >
              <UserAvatar user={props.parent.user} />
            </Link>
            <div className="card-content-header">
              <div className="card-header-detail">
                <span className="card-header-user">
                  <Link
                    onClick={(e) => e.stopPropagation()}
                    to={`/profile/${props.parent.user._id}`}
                  >
                    {props.parent.user.name}
                  </Link>
                </span>
              </div>
              <div className="card-header-more"></div>
            </div>
            <div className="carvibes-reply-thread"></div>
          </div>
          <div className="card-content-wrapper">
            {props.parent.images[0] && (
              <div className="card-content-images">
                <div className="card-image-link">
                  <img alt="" src={props.parent.images[0]} />
                </div>
              </div>
            )}
            <div className="card-buttons-wrapper">
              <div
                onClick={(e) => {
                  likeCarvibes(e, props.parent._id);
                  setTimeout(() => {
                    props.carvibesUpdated(props.parent._id);
                  }, 500);
                }}
                className="card-button-wrap heart-wrap"
              >
                <div className="card-icon heart-icon">
                  <ICON_HEART />
                </div>
                <div className="card-icon-value">
                  {props.parent.likes.length}
                </div>
              </div>
              <div
                onClick={(e) => {
                  if (props.parent._id.length !== 0) toggleModal(e, "parent");
                }}
                className="card-button-wrap reply-wrap"
              >
                <div className="card-icon reply-icon">
                  <ICON_REPLY styles={{ fill: "rgb(101, 119, 134)" }} />
                </div>
                <div className="card-icon-value">
                  {props.parent.replies.length > 0 &&
                    props.parent.replies.length}
                </div>
              </div>
            </div>
            <div
              className="card-content-info"
              style={{ whiteSpace: "pre-wrap" }}
            >
              <Linkify
                options={{ className: 'link-text', target: '_blank' }}
              >
                {props.parent.description
                  .replaceAll("<br>", "\n")
                  .replaceAll("<br />", "\n")
                  .replaceAll("<br/>", "\n")
                  .substr(0, MAX_STRING_LENGTH) + "\u2026"}
              </Linkify>
              <br />
              {dayjs(props.parent.createdAt).format(
                getDatetimeFormat(countryCode)
              )}
            </div>
          </div>
        </div>
      ) : null}

      {props.user ? (
        <div key={props.id} className="Carvibes-card-wrapper">
          <div className="card-userPic-wrapper">
            <Link
              onClick={(e) => e.stopPropagation()}
              to={`/profile/${props.user._id}`}
            >
              <UserAvatar user={props.user} />
            </Link>
            <div className="card-content-header">
              <div className="card-header-detail">
                <span className="card-header-user">
                  <Link
                    onClick={(e) => e.stopPropagation()}
                    to={`/profile/${props.user._id}`}
                  >
                    {props.user.name}
                  </Link>
                </span>
              </div>
              <div className="card-header-more"></div>
            </div>
            <FollowButton user={props.user} show={props.showFollow} />
            {props.user._id === account._id && props.showEdit ?
              <div className="edit-delete-container">
                <div className='translate-button'
                  style={{ marginLeft: "auto", marginRight: "10px" }}
                  onClick={(e) => { setEditCarvibesData(props) }}
                >
                  編集
                </div>
                <div className='translate-button'
                  onClick={(e) => {
                    setDeleteCarvibesId(props.id)
                  }}
                >
                  削除
                </div>
              </div> : null}
          </div>
          <div className="card-content-wrapper">
            {props.images && props.images[0] && (
              <div className="card-content-images">
                <div className="card-image-link">
                  <div
                    style={{
                      width: "Calc( 100% - 0.5px )",
                      height: "350px",
                    }}
                  >
                    <Carousel
                      className="slider"
                      emulateTouch
                      showThumbs={false}
                      showStatus={false}
                      onClickItem={(index, item) => setPopUpImageSrc(item.key)}
                    >
                      {props.images.map((image) => {
                        return (
                          <img
                            alt=""
                            key={image}
                            src={image}
                            role="presentation"
                          />
                        );
                      })}
                    </Carousel>
                  </div>
                </div>
              </div>
            )}
            <div className="card-buttons-wrapper">
              <div
                onClick={(e) => {
                  likeCarvibes(e, props.id);
                  setTimeout(() => {
                    props.carvibesUpdated(props.id);
                  }, 500);
                }}
                className="card-button-wrap heart-wrap"
              >
                <div className="card-icon heart-icon">
                  <ICON_HEART />
                </div>
                <div className="card-icon-value">{props.likes.length}</div>
              </div>
              <div
                onClick={(e) => {
                  if (props.id.length !== 0) toggleModal(e);
                }}
                className="card-button-wrap reply-wrap"
              >
                <div className="card-icon reply-icon">
                  <ICON_REPLY styles={{ fill: "rgb(101, 119, 134)" }} />
                </div>
                <div className="card-icon-value">
                  {props.replies.length > 0 && props.replies.length}
                </div>
              </div>
              {
                props.showReport ?
                  <div className={
                    reported ? "card-button-wrap" : "card-button-wrap report-button"
                  }
                    style={{ marginLeft: "auto" }}
                    onClick={(e) => {
                      if (reported) return;
                      if (props.id.length !== 0) onReport(e, props.id);
                    }}
                  >
                    {reported ? <IoIosFlag style={{
                      fill: reported ? "red" : "rgb(0, 0, 0)",
                    }} />
                      : "Report"}
                  </div>
                  : null
              }
            </div>
            <div
              className="card-likes-exist"
              onClick={() => setLikesOpen(!likesOpen)}
            >
              {props.likes.length > 0
                ? likedByText +
                " ：" +
                props.likes
                  .map((likedBy) => likedBy.name)
                  .join(" ,")
                  .substr(0, MAX_STRING_LENGTH) +
                "\u2026"
                : ""}
            </div>
            <div className="card-content-info">
              <div className='carvibes-content-section'>
                <p>
                  <Linkify
                    options={{ className: 'link-text', target: '_blank' }}
                  >
                    {showMore
                      ? translatedDescription
                      : translatedDescription
                        .replaceAll("<br>", "\n")
                        .replaceAll("<br />", "\n")
                        .replaceAll("<br/>", "\n")
                        .substr(0, MAX_STRING_LENGTH) +
                      (translatedDescription.length > MAX_STRING_LENGTH
                        ? "\u2026"
                        : "")}
                  </Linkify>
                </p>
                <div
                  style={{
                    width: "20px",
                    height: "20px",
                  }}
                  onClick={() => {
                    setShowMore(!showMore);
                  }}
                >
                  {translatedDescription.length > MAX_STRING_LENGTH ? (
                    showMore ? (
                      <ICON_MINUS styles={{ fill: "rgb(101, 119, 134)" }} />
                    ) : (
                      <ICON_PLUS styles={{ fill: "rgb(101, 119, 134)" }} />
                    )
                  ) : null}
                </div>
                {props.isTranslationEnabled ?
                  <div className='translate-button-section'>
                    <div
                      onClick={(e) => handleTranslateDescription(translatedDescription)}
                      className='translate-button'
                    >
                      翻訳
                    </div>
                    <Tooltip
                      content={
                        <>
                          翻訳機能は日本語の投稿を多言語に翻訳する機能となります
                          <br />
                          This is a function to translate the Japanese translation function into multiple languages.
                        </>
                      }
                      direction="left"
                    >
                      <BsInfoCircle data-tooltip-id="my-tooltip-2" className='translate-tooltip' fontSize={24} />
                    </Tooltip>
                  </div>
                  : null
                }

              </div>
              <br />
              {dayjs(props.createdAt).format(getDatetimeFormat(countryCode))}
            </div>
          </div>
        </div>
      ) : null}

      {props.parent || props.user ? (
        <div
          style={{ display: likesOpen ? "block" : "none" }}
          className="modal-edit"
        >
          {likesOpen ? (
            <div
              style={{ minHeight: "500px", height: "initial" }}
              className="modal-content"
            >
              <div className="modal-header">
                <p className="modal-title">CarVibesリスト</p>
                <div
                  onClick={() => setLikesOpen(!likesOpen)}
                  className="modal-closeIcon-wrap"
                >
                  <ICON_CLOSE />
                </div>
              </div>
              <div
                style={{ marginTop: "3em", marginLeft: "20px" }}
                className="modal-body"
              >
                {props.likes.map((likedBy) => (
                  <Link
                    onClick={(e) => e.stopPropagation()}
                    to={`/profile/${likedBy._id}`}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          marginBottom: "10px"
                        }}
                      >
                        <div
                          style={{
                            width: "40px",
                            height: "40px",
                            borderRadius: "50%",
                            overflow: "hidden",
                            marginRight: "10px",
                          }}
                        >
                          <img
                            style={{
                              width: "100%",
                              height: "100%",
                              objectFit: "cover",
                            }}
                            src={likedBy.profileImg}
                            alt=""
                          />
                        </div>
                        <div>
                          <p>
                            {likedBy && likedBy.name}
                          </p>
                        </div>
                      </div>
                    </div>
                  </Link>
                ))}
              </div>
            </div>
          ) : null}
        </div>
      ) : null}

      {props.parent || props.user ? (
        <div
          onClick={() => toggleModal()}
          style={{ display: modalOpen ? "block" : "none" }}
          className="modal-edit"
        >
          {modalOpen ? (
            <div
              style={{ minHeight: "500px", height: "initial" }}
              onClick={(e) => handleModalClick(e)}
              className="modal-content"
            >
              <div className="modal-header">
                <div className="modal-closeIcon">
                  <div
                    onClick={() => toggleModal()}
                    className="modal-closeIcon-wrap"
                  >
                    <ICON_ARROWBACK />
                  </div>
                </div>
                <p className="modal-title">{translatedTexts[2]}</p>
              </div>
              <div style={{ marginTop: "10px" }} className="modal-body">
                <div className="reply-content-wrapper">
                  <div className="card-content-wrapper-2">
                    {props?.replies?.map((r, i) => {
                      return (
                        <div
                          style={{ marginTop: "5px", marginBottom: "20px" }}
                          key={i + "__replies"}
                        >
                          <div className="card-userPic-wrapper">
                            <UserAvatar user={r?.user} />
                            <div className="card-content-header">
                              <div className="card-header-detail">
                                <span className="card-header-user">
                                  <Link
                                    onClick={(e) => e.stopPropagation()}
                                    to={`/profile/${r._id}`}
                                  >
                                    {r.name}
                                  </Link>
                                </span>
                              </div>
                            </div>
                          </div>
                          <div
                            className="card-content-info"
                            style={{
                              marginTop: "5px",
                              whiteSpace: "pre-wrap",
                            }}
                          >
                            <span
                              dangerouslySetInnerHTML={{
                                __html: r.description
                                  .replaceAll("<br>", "\n")
                                  .replaceAll("<br />", "\n")
                                  .replaceAll("<br/>", "\n"),
                              }}
                            />
                            < br />
                            {timeDisplay(r.createdAt)}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
                <div
                  style={{ position: "relative", backgroundColor: "white" }}
                  className="Carvibes-input-wrapper"
                >
                  <div className="Carvibes-profile-wrapper">
                    <div>
                      <UserAvatar user={account} />
                    </div>
                  </div>
                  <div
                    onClick={() => document.getElementById("replyBox").focus()}
                    className="Carvibes-input-side"
                    style={{
                      flexDirection: "row",
                    }}
                  >
                    <div
                      className="inner-input-box"
                      style={{
                        width: "100%",
                      }}
                    >
                      <ContentEditable
                        id="replyBox"
                        style={{ minHeight: "30px", minWidth: "100px" }}
                        className={
                          replyText.length ? "carvibes-input-active" : null
                        }
                        placeholder="Reply"
                        html={carvibesT.current}
                        onChange={handleChange}
                        onCompositionStart={setComposing.bind(null, true)}
                        onCompositionEnd={(evt) => {
                          setComposing(false);
                          handleChange({
                            target: { value: composition.current },
                          });
                        }}
                      />
                    </div>
                    <div
                      className="inner-input-links"
                      style={{
                        width: "100%",
                        background: "white",
                        margin: "0px",
                        justifyContent: "end",
                      }}
                    >
                      <div className="carvibes-btn-holder">
                        <div
                          onClick={() =>
                            replyCarvibes(
                              parent
                                ? "parent"
                                : props.recarvibes
                                  ? "recarvibes"
                                  : "none"
                            )
                          }
                          className={
                            replyText.length
                              ? "carvibes-btn-side carvibes-btn-active"
                              : "carvibes-btn-side"
                          }
                        >
                          {translatedTexts[0]}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : null}
        </div>
      ) : null}

      {props.clickableImage && props.user && popUpImageSrc !== "" ? (
        <div
          onClick={() => {
            setPopUpImageSrc("");
          }}
          className="carousel-modal-container"
        >
          <div
            id="imageCarouselModal"
            className="image-carousel-modal"
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <img
              alt=""
              src={popUpImageSrc}
              style={{ width: "100%", height: "100%", objectFit: "cover" }}
            />
          </div>
        </div>
      ) : null}

      {reportModalOpen ? (
        <div
          className="modal-edit"
          onClick={() => {
            setReportModalOpen(false);
            setSelectedCarvibesForReport("");
          }}
        >
          <div
            style={{
              position: "fixed",
              top: "50%",
              left: "50%",
              transform: "translate(-50%,-50%)",
              backgroundColor: "white",
              padding: "20px",
              borderRadius: "10px",
              width: "350px",
              height: "175px",
              textAlign: "center",
              display: reportModalOpen ? "block" : "none"
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                height: "100%",
              }}
            >
              <p style={{
                fontWeight: "bold",
                fontSize: "19px",
              }}>
                アラートの内容は「本当に報告してもよろしいですか？
              </p>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <div
                  onClick={() => reportCarvibes()}
                  style={{
                    color: "white",
                    padding: "10px 20px",
                    margin: "0 10px",
                    width: "100%",
                    background:
                      "linear-gradient(89.94deg, #395A37 -0.36%, #2B4773 99.95%)",
                    boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.122)",
                    borderRadius: "4px",
                    cursor: "pointer",
                  }}
                >
                  Report
                </div>

                <div
                  onClick={() => {
                    setReportModalOpen(false);
                    setSelectedCarvibesForReport("");
                  }}
                  style={{
                    color: "white",
                    padding: "10px 20px",
                    margin: "0 10px",
                    width: "100%",
                    background:
                      "linear-gradient(89.94deg, #395A37 -0.36%, #2B4773 99.95%)",
                    boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.122)",
                    borderRadius: "4px",
                    cursor: "pointer",
                  }}
                >
                  Close
                </div>
              </div>
            </div>
          </div>

        </div>) : null}

      {translationAlert ?
        <InformModal
          isOpen={translationAlert}
          title={translationAlert}
          onClose={() => setTranslationAlert('')}
          modalWidth="350px"
          textPadding="8px 0"
        /> : null}

      {deleteCarvibesId ? (
        <div
          className="modal-edit"
          onClick={() => {
            setDeleteCarvibesId("")
          }}
        >
          <div
            style={{
              position: "fixed",
              top: "50%",
              left: "50%",
              transform: "translate(-50%,-50%)",
              backgroundColor: "white",
              padding: "20px",
              borderRadius: "10px",
              width: "350px",
              height: "175px",
              textAlign: "center",
              display: deleteCarvibesId ? "block" : "none"
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                height: "100%",
              }}
            >
              <p style={{
                fontWeight: "bold",
                fontSize: "19px",
              }}>
                本当にこの投稿を削除しますか？
              </p>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <div
                  onClick={(e) => deleteCarvibes(e, deleteCarvibesId)}
                  style={{
                    color: "white",
                    padding: "10px 20px",
                    margin: "0 10px",
                    width: "100%",
                    background:
                      "linear-gradient(89.94deg, #395A37 -0.36%, #2B4773 99.95%)",
                    boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.122)",
                    borderRadius: "4px",
                    cursor: "pointer",
                  }}
                >
                  はい
                </div>

                <div
                  onClick={() => setDeleteCarvibesId("")}
                  style={{
                    color: "white",
                    padding: "10px 20px",
                    margin: "0 10px",
                    width: "100%",
                    background:
                      "linear-gradient(89.94deg, #395A37 -0.36%, #2B4773 99.95%)",
                    boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.122)",
                    borderRadius: "4px",
                    cursor: "pointer",
                  }}
                >
                  いいえ
                </div>
              </div>
            </div>
          </div>

        </div>) : null}

      {props.user && editCarvibesData ? <EditCarvibesModal postData={editCarvibesData} onClose={() => { setEditCarvibesData(null) }} /> : null}

    </div>
  );
});

export default withRouter(CarvibesCard);
