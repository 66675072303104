import React from 'react';
import './style.scss';

const CreateDMButton = ({ setOpenUsers, setChatMode }) => {
  return (
    <button
      className='chat-button'
      onClick={() => {
        setChatMode('dm');
        setOpenUsers(true);
      }}
    >
      DMを送る
    </button>
  );
};

export default CreateDMButton;