import React, { useEffect, useState, useContext } from "react";
import "../Profile/style.scss";
import "./style.scss";
import { ICON_UPLOAD, ICON_ARROWBACK } from "../../Icons";
import { withRouter } from "react-router-dom";
import { StoreContext } from "../../store/store";
import Loader from "../Loader";
import { API_URL } from "../../config";
import axiosLib from "../../axiosLib";
import { useLocalization } from "../../hooks/useLocalization";
import { compressImage, convertFromHeic, supportedImageFiletypes } from "../../utils/common";
import Avatar from 'react-avatar';
import { ImSpinner2 } from "react-icons/im";

const SCREEN_TEXT = [
  "アカウント情報の編集",
  "保存",
  "あなたのアイコン",
  "ユーザー名",
  "メールアドレス",
  "自己紹介 *（※全角50文字で自動改行されます）文字数：",
];

const ProfileEdit = (props) => {
  const { state, actions } = useContext(StoreContext);
  const [editName, setName] = useState("");
  const [editBio, setBio] = useState("");
  const [avatar, setAvatar] = useState("");
  const [avatarFile, setAvatarFile] = useState(null);
  const [isConverting, setIsConverting] = useState(false); // State for loading indicator
  const { user } = state;
  const userParam = props.match.params.userid;

  const { translatedTexts: texts } = useLocalization(SCREEN_TEXT);

  useEffect(() => {
    const currentToken = localStorage.getItem("carvibestoken");
    if (!(currentToken && currentToken.length > 0)) {
      window.location = "/login";
    }
    window.scrollTo(0, 0);
    actions.getUser(props.match.params.userid);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.match.params.userid]);

  useEffect(
    () => () => (document.getElementsByTagName("body")[0].style.cssText = ""),
    []
  );

  useEffect(() => {
    if (user && !avatar) {
      setAvatar(user.profileImg);
      setName(user.name || "");
      setBio(user.description || "");
    }
  }, [avatar, user])

  const editProfile = async () => {
    let imageUrl;
    if (avatarFile) {
      imageUrl = await uploadImage([avatarFile], "avatar");
    }

    let values = {
      name: editName,
      description: editBio,
      profileImg: imageUrl,
      _id: userParam,
    };
    console.log(`VALUES: ${JSON.stringify(values)}`);
    actions.updateUser(values);
    props.history.push(`/settings/${props.match.params.userid}`);
  };

  const handleModalClick = (e) => {
    e.stopPropagation();
  };

  const uploadImage = async () => {
    let bodyFormData = new FormData();

    if (!avatarFile) return;

    const compressedImage = await compressImage(avatarFile, 0.1);
    bodyFormData.append("images", compressedImage);

    try {
      const res = await axiosLib.post(`${API_URL}/carvibes/upload`, bodyFormData);
      const imgUrl = res.data.imageUrls.length > 0 ? res.data.imageUrls[0] : "";
      return imgUrl;
    } catch (err) {
      console.log(err);
      alert("error uploading image");
    }
  };

  const changeAvatar = async (event) => {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      if (!file) return;

      if (file.type === 'image/heic' || file.type === 'image/heif') {
        setIsConverting(true);

        const converted = await convertFromHeic(file);
        const imageUrl = URL.createObjectURL(converted);
        setAvatarFile(converted);
        setAvatar(imageUrl);

        setIsConverting(false);
        return;
      }

      const imageUrl = URL.createObjectURL(file);
      setAvatar(imageUrl);
      setAvatarFile(file);
    }
  };

  const calculateRows = () => {
    const lineBreaks = (editBio.match(/\n/g) || []).length + 1;
    const contentRows = Math.ceil(editBio.length / 90);
    return Math.max(Math.max(lineBreaks, contentRows), 3); // preserve at least 3 rows
  };


  return (
    <div className="profile-edit-root">
      {user ? (
        <div>
          <div className="modal-edit">
            <div onClick={(e) => handleModalClick(e)} className="modal-content">
              <div className="modal-header">
                <div className="modal-closeIcon">
                  <div
                    onClick={() => props.history.goBack()}
                    className="modal-closeIcon-wrap"
                  >
                    <ICON_ARROWBACK />
                  </div>
                </div>
                <p
                  className="modal-title"
                  style={{
                    fontSize: "0.8em",
                  }}
                >
                  {texts[0]}
                </p>

                <div className="save-modal-wrapper">
                  <div onClick={editProfile} className="save-modal-btn">
                    {texts[1]}
                  </div>
                </div>
              </div>

              <div className="modal-body">
                <div className="sp-header">
                  <div
                    className="modal-closeIcon-wrap"
                    onClick={() => props.history.goBack()}
                  >
                    <ICON_ARROWBACK color="#ffffff" />
                  </div>
                  <p className="modal-title">{texts[0]}</p>
                </div>

                <div className="modal-profile-pic-wrapper">
                  <p>{texts[2]}</p>
                  <div className="edit-profile-modal-pic">
                    <div
                      className="edit-back-pic"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}>
                      {isConverting ? (
                        // Show the loading indicator while converting
                        <ImSpinner2
                          className="spinner"
                          size={30}
                          style={{ color: "white", animation: "spin 1s linear infinite" }}
                        />
                      ) : (
                        // Show the avatar when not converting
                        <Avatar
                          name={user.name}
                          src={avatar}
                          size={120}
                          round={true}
                        />
                      )}
                      <div>
                        <ICON_UPLOAD />
                        <input
                          onChange={(e) => changeAvatar(e)}
                          title=" "
                          id="avatar"
                          accept={supportedImageFiletypes.join(", ")}
                          style={{ opacity: "0" }}
                          type="file"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <form className="edit-form">
                  <div className="edit-input-wrap">
                    <div className="edit-input-content">
                      <label>
                        {texts[3]} <sup>*</sup>
                      </label>
                      <input
                        defaultValue={user.name}
                        onChange={(e) => setName(e.target.value)}
                        type="text"
                        name="name"
                        className="edit-input"
                      />
                    </div>
                  </div>
                  <div className="edit-input-wrap">
                    <div className="edit-input-content">
                      <label>
                        {texts[4]} <sup>*</sup>
                      </label>
                      <input
                        defaultValue={user.email}
                        disabled
                        type="text"
                        name="name"
                        className="edit-input"
                      />
                    </div>
                  </div>
                  <div className="edit-input-wrap">
                    <div className="edit-input-content">
                      <label>
                        {texts[5]}{editBio.length}
                      </label>
                      <textarea
                        // defaultValue={user.description}
                        value={editBio}
                        onChange={(e) => setBio(e.target.value)}
                        type="text"
                        name="bio"
                        className="edit-input"
                        rows={calculateRows()}
                        maxLength={1000}
                      />
                    </div>
                  </div>
                  <div
                    className="sp-save-modal-wrapper"
                    style={{
                      margin: "20px auto 0 auto"
                    }}
                  >
                    <div
                      onClick={editProfile}
                      className="save-modal-btn"
                      style={{ color: "white" }}
                    >
                      {"保存"}
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="profile-wrapper">
          <Loader />{" "}
        </div>
      )}
    </div>
  );
};

export default withRouter(ProfileEdit);