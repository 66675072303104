import React, { useEffect, useState, useContext, useRef } from "react";
import { StoreContext } from "../../store/store";
import { Link, withRouter } from "react-router-dom";
import "./style.scss";
import {
  ICON_HOME,
  ICON_HASH,
  ICON_USER,
  ICON_SETTINGS,
  ICON_FEATHER,
  ICON_ARROWBACK,
  ICON_HEART_DARK,
  ICON_CHAT,
} from "../../Icons";
import axios from "../../axiosLib";
import axiosLib from "../../axiosLib";
import { API_URL } from "../../config";
import { TagsInput } from "react-tag-input-component";
import CarvibesCard from "../CarvibesCard";
import { country_lng } from "../../constants/language";
import CategorySelector from "../CategorySelector";
import ModalFollowings from "../Profile/ModalFollowing";
import { compressImage, convertFromHeic, supportedImageFiletypes } from "../../utils/common";
import UserAvatar from "../UserAvatar";
import { useGetChatRooms } from "../../client-api";
import { ImSpinner2 } from "react-icons/im";

const Nav = ({ history }) => {
  const { state, actions } = useContext(StoreContext);

  const { account, session, followers, following } = state;
  const [modalOpen, setModalOpen] = useState(false);
  const [hashtags, setHashtags] = useState([]);
  const [selectedCategories, setCategories] = useState([]);
  const [openOkModal, setOpenOkModal] = useState(false);
  const [popupState, setPopupState] = useState(0);
  const [styleBody, setStyleBody] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  const [carvibesText, setCarvibesText] = useState("");
  const [carvibesImages, setCarvibesImages] = useState(null);
  const [imageLoaded, setImageLoaded] = useState(false);
  const [isUploading, setIsUploading] = useState(false);

  const [showFollowModal, setShowFollowModal] = useState(false);

  const queryEnabled = !!account;
  const { data: rooms } = useGetChatRooms(queryEnabled);

  const totalUnreadCount = rooms
    ? rooms.reduce((n, { unreadCount }) => n + unreadCount, 0)
    : undefined;

  const [texts, setTexts] = useState([
    "投稿一覧",
    "検索",
    "投稿する",
    "マイページ",
    "設定",
    "投稿しました",
    "新規投稿",
    "プレビュー",
    "次へ",
    "投稿する",
    "キャプション",
    "愛車について自由に記録してください。",
    "タグ",
    "カテゴリ（任意）",
  ]);

  const updateTexts = (code) => {
    axios
      .post(
        `${API_URL}/translate`,
        {
          texts,
          destination: country_lng[code],
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("carvibestoken")}`,
          },
        }
      )
      .then((res) => {
        let resp = res.data;
        const newTexts = resp.map((r) => r.text.replace(/ *\([^)]*\) */g, ""));
        setTexts(newTexts);
      })
      .catch((err) => actions.alert("error uploading image"));
  };

  const carvibesT = useRef("");

  const isInitialMount = useRef(true);
  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
    } else {
      document.getElementsByTagName("body")[0].style.cssText =
        styleBody && "overflow-y: hidden; margin-right: 17px";
    }
  }, [styleBody]);

  useEffect(
    () => () => (document.getElementsByTagName("body")[0].style.cssText = ""),
    []
  );

  useEffect(() => {
    let ran = false;
    history.listen((location, action) => {
      state.account == null
        ? actions.verifyToken("get account")
        : actions.verifyToken();
    });
    if (localStorage.getItem("lang") !== "jp")
      updateTexts(localStorage.getItem("lang"));
    !ran && state.account == null
      ? actions.verifyToken("get account")
      : actions.verifyToken();
  }, []);

  const path = history.location.pathname.slice(0, 5);

  const uploadImage = async (files) => {
    const compressedImages = [];
    setIsUploading(true)
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      let processedFile;

      if (file.type === 'image/heic' || file.type === 'image/heif') {
        const converted = await convertFromHeic(file, 0.1);
        processedFile = await compressImage(converted, 0.2);
      } else {
        processedFile = await compressImage(file);
      }
      compressedImages.push(processedFile);
    }

    let bodyFormData = new FormData();
    for (let i = 0; i < compressedImages.length; i++) {
      const img = compressedImages[i];
      bodyFormData.append("images", img);
    }

    try {
      const res = await axiosLib.post(`${API_URL}/carvibes/upload`, bodyFormData);
      let imgUrls = res.data.imageUrls;
      let new_arr = carvibesImages ? [...carvibesImages, ...imgUrls] : imgUrls;
      setCarvibesImages(new_arr);

      const imageInput = document.getElementById("image_2");
      if (imageInput) {
        imageInput.value = "";
      }
      setIsUploading(false);
    } catch (err) {
      console.log(err);
      alert("Error uploading image");
      setIsUploading(false);
    }
  };

  const onchangeImage = async (event) => {
    if (carvibesImages && carvibesImages.length >= 5) return;
    let files = event.target.files;
    if (files && files.length > 5) return;

    await uploadImage(files);
  };

  const removeImage = (index) => {
    let new_arr = [...carvibesImages];
    new_arr.splice(index, 1);
    setCarvibesImages(new_arr);
    setImageLoaded(false);
  };

  const toggleModal = (e, type) => {
    if (e) {
      e.stopPropagation();
    }
    setStyleBody(!styleBody);
    setTimeout(() => {
      setModalOpen(!modalOpen);
      if (!modalOpen) {
        document.documentElement.classList.add("scroll-disabled");
      } else {
        document.documentElement.classList.remove("scroll-disabled");
      }
    }, 20);
  };

  const handleModalClick = (e) => {
    e.stopPropagation();
  };

  const handleChange = (evt) => {
    carvibesT.current = evt.target.value;
    setCarvibesText(carvibesT.current);
  };

  const canSubmit =
    carvibesText.trim().length > 0 &&
    hashtags.length > 0 &&
    carvibesImages.length > 0;

  const submitCarvibes = (type) => {
    toggleModal();
    if (!canSubmit && isUploading) {
      return;
    }
    const values = {
      description: carvibesText,
      images: carvibesImages,
      hashtags: hashtags,
      categories: selectedCategories,
    };
    actions.carvibes(values);
    carvibesT.current = "";
    setCarvibesText("");
    setHashtags([]);
    setCategories([]);
    setSubmitted(false);
    setPopupState(0);
    setOpenOkModal(true);
    setCarvibesImages(null);
  };

  return (
    <>
      <div className="Nav-component">
        <div className="Nav-width">
          <div className="Nav">
            <div className="Nav-Content">
              <nav className="Nav-wrapper">
                <Link to={`/home`} className="logo-wrapper btn-hide">
                  <img width={"100%"} src="/logo.png" alt="Logo" />
                </Link>
                {session && account ? (
                  <Link
                    className="Nav-link btn-hide"
                    to={`/profile/${account && account._id}`}
                    style={{
                      flexDirection: "column",
                      textAlign: "center",
                    }}
                  >
                    <div
                      style={{
                        marginLeft: "auto",
                        marginRight: "auto",
                      }}
                    >
                      <UserAvatar size={60} user={account} />
                    </div>

                    <div
                      style={{
                        marginTop: "0.5em",
                      }}
                      className="Nav-item"
                    >
                      {account.name}
                    </div>
                    <div
                      style={{
                        color: "#5A5A5A",
                        fontSize: "0.8em",
                        marginTop: "0.2em",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                      className="Nav-item"
                    >
                      total {account.totalLikes}{" "}
                      <div
                        style={{
                          marginTop: 5,
                          marginLeft: 3,
                          marginRight: 3,
                        }}
                      >
                        <ICON_HEART_DARK />
                      </div>{" "}
                      carvibes!!
                    </div>

                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "center",
                        gap: "10px",
                        marginTop: "8px",
                      }}
                    >
                      <span
                        onClick={() => {
                          setShowFollowModal(true);
                        }}
                      >
                        <span className="follow-count">
                          {(following ?? []).length}
                        </span>{" "}
                        <span className="follow-lbl">フォロー</span>
                      </span>

                      <span
                        onClick={() => {
                          setShowFollowModal(true);
                        }}
                      >
                        <span className="follow-count">
                          {(followers ?? []).length}
                        </span>{" "}
                        <span className="follow-lbl">フォロワー</span>
                      </span>
                    </div>
                  </Link>
                ) : (
                  <></>
                )}
                <Link className="Nav-link btn-hide" to={`/home`}>
                  <div
                    className={
                      path === "/home"
                        ? "Nav-item-hover active-Nav"
                        : "Nav-item-hover"
                    }
                  >
                    <ICON_HOME />
                    <div className="Nav-item">{texts[0]}</div>
                  </div>
                </Link>
                <Link className="Nav-link btn-show" to={`/home`}>
                  <div
                    className={
                      path === "/home"
                        ? "Nav-item-hover active-Nav"
                        : "Nav-item-hover"
                    }
                  >
                    <img
                      src="/small.png"
                      alt="small logo"
                      style={{
                        height: "20px",
                      }}
                    />
                  </div>
                </Link>
                <div className="navicons">
                  {session ? (
                    <div className="Nav-link">
                      <div
                        onClick={() => toggleModal()}
                        className="Nav-item-hover"
                      >
                        <ICON_FEATHER />
                        <div className="Nav-item btn-hide">{texts[2]}</div>
                      </div>
                    </div>
                  ) : null}
                  <Link to="/chat" className="Nav-link">
                    <div
                      className={
                        path === "/chat"
                          ? "Nav-item-hover active-Nav"
                          : "Nav-item-hover"
                      }
                    >
                      <ICON_CHAT />
                      <div className="Nav-item">{"メッセージ"}</div>
                      {totalUnreadCount > 0 && (
                        <div className="Chat-room-badge-nav">
                          {totalUnreadCount}
                        </div>
                      )}
                    </div>
                  </Link>
                  <Link to="/explore" className="Nav-link">
                    <div
                      className={
                        path === "/expl"
                          ? "Nav-item-hover active-Nav"
                          : "Nav-item-hover"
                      }
                    >
                      <ICON_HASH />
                      <div className="Nav-item">{texts[1]}</div>
                    </div>
                  </Link>
                  {session ? (
                    <>
                      <Link
                        className="Nav-link"
                        to={`/profile/${account && account._id}`}
                      >
                        <div
                          className={
                            path === "/prof"
                              ? "Nav-item-hover active-Nav"
                              : "Nav-item-hover"
                          }
                        >
                          <ICON_USER />
                          <div className="Nav-item">{texts[3]}</div>
                        </div>
                      </Link>
                      <Link
                        className="Nav-link"
                        to={`/settings/${account?._id}`}
                      >
                        <div
                          className={
                            path === "/setting"
                              ? "Nav-item-hover active-Nav"
                              : "Nav-item-hover"
                          }
                        >
                          <ICON_SETTINGS />
                          <div className="Nav-item">{texts[4]}</div>
                        </div>
                      </Link>
                    </>
                  ) : null}
                </div>
              </nav>
              <div></div>
            </div>
          </div>
        </div>
        {account && (
          <ModalFollowings
            userId={account._id}
            show={showFollowModal}
            onClose={() => setShowFollowModal(false)}
          />
        )}
        {account && createPopup()}
        {openOkModal && modal()}
      </div>
    </>
  );

  // create a modal component
  function modal() {
    return (
      <div
        style={{
          // full screen
          position: "fixed",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          zIndex: 1000,
        }}
      >
        <div
          style={{
            // transparent background , full screen
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0,0,0,0.5)",
          }}
        ></div>
        <div
          style={{
            // modal content
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%,-50%)",
            backgroundColor: "white",
            padding: "20px",
            borderRadius: "10px",
            width: "300px",
            textAlign: "center",
            height: "150px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <p>{texts[5]}</p>
          <div
            onClick={() => setOpenOkModal(false)}
            style={{
              color: "white",
              padding: "10px 20px",
              background:
                "linear-gradient(89.94deg, #395A37 -0.36%, #2B4773 99.95%)",
              boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.122)",
              borderRadius: "4px",
              cursor: "pointer",
            }}
          >
            完了
          </div>
        </div>
      </div>
    );
  }

  function createPopup() {
    return (
      <div
        onClick={() => toggleModal()}
        style={{ display: modalOpen ? "block" : "none" }}
        className="modal-edit"
      >
        <div
          style={{ minHeight: "270px" }}
          onClick={(e) => handleModalClick(e)}
          className="modal-content"
        >
          <div className="modal-header">
            <div className="modal-closeIcon">
              <div
                onClick={() => {
                  if (popupState === 0) toggleModal();
                  else setPopupState(0);
                }}
                className="modal-closeIcon-wrap"
              >
                <ICON_ARROWBACK />
              </div>
            </div>
            <p className="modal-title">
              {popupState === 0 ? texts[6] : texts[7]}
            </p>
            <p
              className="modal-action carvibes-btn-side carvibes-btn-active"
              onClick={() => {
                setSubmitted(true);
                if (!canSubmit) {
                  return;
                }
                if (popupState === 0) {
                  setPopupState(1);
                } else {
                  submitCarvibes("none");
                }
              }}
            >
              {popupState === 0 ? texts[8] : texts[9]}
            </p>
          </div>
          <div style={{ marginTop: "5px" }} className="modal-body">
            {popupState === 0 && (
              <div
                style={{ position: "relative" }}
                className="Carvibes-input-wrapper"
              >
                <div className="Carvibes-profile-wrapper">
                  <div>
                    <UserAvatar user={account} />
                  </div>
                </div>
                <div className="inner-image-root">
                  {carvibesImages &&
                    carvibesImages.map((carvibesImage, index) => {
                      return (
                        <div
                          className="inner-image-box"
                          key={`carvibesImage_${index}`}
                        >
                          <img
                            onLoad={() => setImageLoaded(true)}
                            className="carvibes-upload-image"
                            src={carvibesImage}
                            alt="carvibesImage"
                          />
                          {imageLoaded && (
                            <span
                              onClick={removeImage.bind(this, index)}
                              className="cancel-image"
                            >
                              x
                            </span>
                          )}
                        </div>
                      );
                    })}
                  {isUploading ?
                    <div
                      className="inner-image-box"
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <ImSpinner2
                        className="spinner"
                        size={20}
                        style={{ color: "black", animation: "spin 1s linear infinite" }}
                      />
                    </div> : null}
                  <div
                    style={{ marginLeft: "-10px" }}
                    className="input-attach-wrapper"
                  >
                    <div
                      style={{
                        height: "80px",
                        width: "80px",
                        display: "block",
                        border: "1px dotted grey",
                        textAlign: "center",
                        alignContent: "center",
                        justifyContent: "center",
                        alignItems: "center",
                        borderRadius: "10px",
                        cursor: "pointer",
                        lineHeight: "75px",
                        marginTop: "10px",
                        marginBottom: "20px",
                        marginLeft: "10px",
                      }}
                    >
                      +
                      <input
                        title=" "
                        id="image_2"
                        style={{ opacity: "0" }}
                        type="file"
                        multiple={true}
                        accept={supportedImageFiletypes.join(", ")}
                        onChange={(e) => onchangeImage(e)}
                      />
                    </div>
                  </div>
                </div>
                {submitted && !carvibesImages && (
                  <p style={{ color: "red" }}>必須項目です</p>
                )}
                <div className="Carvibes-input-side">
                  <p
                    style={{
                      marginBottom: "5px",
                      color: "#3E3E3E",
                      fontSize: "0.85em",
                    }}
                  >
                    {texts[10]}
                  </p>
                  <div className="inner-input-box">
                    <textarea
                      id="carvibesPop1"
                      style={{
                        minHeight: "130px",
                        minWidth: "100px",
                        width: "100%",
                        fontSize: "16px",
                      }}
                      className={
                        carvibesText.length ? "carvibes-input-active" : null
                      }
                      value={carvibesText}
                      placeholder={texts[11]}
                      html={carvibesT.current}
                      onChange={handleChange}
                    />
                  </div>

                  {submitted && !carvibesText && (
                    <p style={{ color: "red", marginTop: "10px" }}>
                      必須項目です
                    </p>
                  )}
                  <div className="inner-tags-box">
                    <p
                      style={{
                        marginBottom: "-15px",
                        marginTop: "15px",
                        color: "#3E3E3E",
                        fontSize: "0.85em",
                      }}
                    >
                      {texts[12]}
                    </p>
                    <TagsInput
                      value={hashtags}
                      onChange={setHashtags}
                      classNames={{
                        input: hashtags.length > 0 ? 'tags-input-narrow' : 'tags-input-wide'
                      }}
                      name="tags"
                      placeHolder={`${hashtags.length > 0 ? "" : "入力したいキーワードを入力してEnterキーを押してください"}`}
                      disabled={isUploading}
                    />
                    {submitted && !hashtags.length && (
                      <p style={{ color: "red" }}>必須項目です</p>
                    )}
                  </div>
                  <CategorySelector
                    title={texts[13]}
                    selectedCategories={selectedCategories}
                    onChange={setCategories}
                  />
                </div>
              </div>
            )}
            {popupState === 1 && (
              <div
                style={{
                  // make the div content to center
                  display: "block",
                  justifyContent: "center",
                  alignItems: "center",
                  marginBottom: "40px",
                }}
              >
                <CarvibesCard
                  showFollow={true}
                  carvibesUpdated={(id) => { }}
                  recarvibes={{ description: "" }}
                  actions={false}
                  username={""}
                  name={""}
                  parent={""}
                  key={"____carvibescard____"}
                  id={""}
                  user={account}
                  createdAt={new Date()}
                  description={carvibesText}
                  images={carvibesImages}
                  replies={[]}
                  likes={[]}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
};

export default withRouter(Nav);
