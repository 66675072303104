import React from 'react';
import './style.scss';

const CreateGroupChatButton = ({ setOpenUsers, setChatMode }) => {
  return (
    <button
      className='chat-button'
      onClick={() => {
        setChatMode('group');
        setOpenUsers(true);
      }}
    >
      グループチャットを送る
    </button>
  );
};

export default CreateGroupChatButton;
