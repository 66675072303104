import types from "./typeActions";
import axios from "../axiosLib";
import { API_URL } from "../config";

export const applyMiddleware = (dispatch) => (action) => {
  const headers = {};
  switch (action.type) {
    case types.FACEBOOK_LOGIN:
      return (window.location = `${API_URL}/auth/facebook`);
    case types.GOOGLE_LOGIN:
      return (window.location = `${API_URL}/auth/google`);

    case types.CARVIBES:
      return axios
        .post(`${API_URL}/carvibes/create`, action.payload, headers)
        .then((res) =>
          dispatch({
            type: types.CARVIBES,
            payload: res.data,
            data: action.payload,
          })
        )
        .catch((err) =>
          dispatch({ type: types.ERROR, payload: err.response.data })
        );

    case types.EDIT_CARVIBES:
      return axios
        .put(`${API_URL}/carvibes/${action.payload.id}`, action.payload.values, headers)
        .then((res) =>
          dispatch({
            type: types.EDIT_CARVIBES,
            payload: res.data.carvibes,
            data: action.payload,
          })
        )
        .catch((err) =>
          dispatch({ type: types.ERROR, payload: err.response.data })
        );

    case types.ADMIN_LOGIN:
      return axios
        .post(`${API_URL}/auth/admin-login`, action.payload, headers)
        .then((res) =>
          dispatch({
            type: types.ADMIN_LOGIN,
            payload: res.data,
            data: action.payload,
          })
        )
        .catch((err) =>
          dispatch({ type: types.ERROR, payload: err.response.data })
        );

    case types.REGISTER:
      return axios
        .post(`${API_URL}/auth/register`, action.payload, headers)
        .then((res) =>
          dispatch({
            type: types.REGISTER,
            payload: res.data,
            data: action.payload,
          })
        )
        .catch((err) =>
          dispatch({ type: types.ERROR, payload: err.response.data })
        );

    case types.LIKE_CARVIBES:
      return axios
        .post(
          `${API_URL}/carvibes/${action.payload.id}/like`,
          action.payload,
          headers
        )
        .then((res) =>
          dispatch({
            type: types.LIKE_CARVIBES,
            payload: res.data,
            data: action.payload,
          })
        )
        .catch((err) =>
          dispatch({ type: types.ERROR, payload: err.response.data })
        );

    case types.GET_CARVIBESS:
      return axios
        .get(`${API_URL}/carvibes`, action.payload)
        .then((res) =>
          dispatch({ type: types.GET_CARVIBESS, payload: res.data })
        )
        .catch((err) =>
          dispatch({ type: types.ERROR, payload: err.response.data })
        );

    case types.GET_CARVIBES:
      return axios
        .get(`${API_URL}/carvibes/${action.payload}`, action.payload)
        .then((res) =>
          dispatch({ type: types.GET_CARVIBES, payload: res.data })
        )
        .catch((err) =>
          dispatch({ type: types.ERROR, payload: err.response.data })
        );

    case types.GET_ACCOUNT:
      return axios
        .get(`${API_URL}/auth/user`, headers)
        .then((res) => dispatch({ type: types.GET_ACCOUNT, payload: res.data }))
        .catch((err) =>
          dispatch({ type: types.ERROR, payload: err.response.data })
        );

    case types.BOOKMARK:
      return axios
        .post(
          `${API_URL}/carvibes/${action.payload.id}/bookmark`,
          action.payload,
          headers
        )
        .then((res) =>
          dispatch({
            type: types.BOOKMARK,
            payload: res.data,
            data: action.payload,
          })
        )
        .catch((err) =>
          dispatch({ type: types.ERROR, payload: err.response.data })
        );

    case types.GET_USER:
      return axios
        .get(`${API_URL}/user/${action.payload}/carvibess`)
        .then((res) => dispatch({ type: types.GET_USER, payload: res.data }))
        .catch((err) =>
          dispatch({ type: types.ERROR, payload: err.response.data })
        );

    case types.GET_BOOKMARKS:
      return axios
        .get(`${API_URL}/user/i/bookmarks`, headers)
        .then((res) =>
          dispatch({ type: types.GET_BOOKMARKS, payload: res.data })
        )
        .catch((err) =>
          dispatch({ type: types.ERROR, payload: err.response.data })
        );

    case types.UPDATE_USER:
      return axios
        .put(`${API_URL}/user/${action.payload._id}`, action.payload, headers)
        .then((res) =>
          dispatch({
            type: types.UPDATE_USER,
            payload: res.data,
            data: action.payload,
          })
        )
        .catch((err) =>
          dispatch({ type: types.ERROR, payload: err.response.data })
        );

    case types.RECARVIBES:
      return axios
        .post(
          `${API_URL}/carvibes/${action.payload.id}/recarvibes`,
          action.payload,
          headers
        )
        .then((res) =>
          dispatch({
            type: types.RECARVIBES,
            payload: res.data,
            data: action.payload,
          })
        )
        .catch((err) =>
          dispatch({ type: types.ERROR, payload: err.response.data })
        );

    case types.DELETE_CARVIBES:
      return axios
        .delete(`${API_URL}/carvibes/${action.payload}/delete`, headers)
        .then((res) =>
          dispatch({
            type: types.DELETE_CARVIBES,
            payload: res.data,
            data: action.payload,
          })
        )
        .catch((err) =>
          dispatch({ type: types.ERROR, payload: err.response.data })
        );

    case types.FOLLOW_USER:
      return axios
        .post(
          `${API_URL}/user/${action.payload.id}/follow`,
          action.payload,
          headers
        )
        .then((res) => {
          dispatch({
            type: types.FOLLOW_USER,
            payload: res.data,
            data: action.payload.id,
          });
          axios
            .get(`${API_URL}/user/${action.payload.userId}/followers`, headers)
            .then((res) => {
              dispatch({
                type: types.GET_FOLLOWERS,
                payload: res.data,
                data: action.payload,
              });
            });
        })
        .catch((err) => {
          console.log({ err });
          dispatch({ type: types.ERROR, payload: err.response.data });
        });

    case types.FOLLOWER_USER:
      return axios
        .post(
          `${API_URL}/user/${action.payload.id}/follower`,
          action.payload,
          headers
        )
        .then((res) => {
          dispatch({
            type: types.FOLLOWER_USER,
            payload: res.data,
            data: action.payload.id,
          });
          axios
            .get(`${API_URL}/user/${action.payload.userId}/followers`, headers)
            .then((res) => {
              dispatch({
                type: types.GET_FOLLOWERS,
                payload: res.data,
                data: action.payload,
              });
            });
        })
        .catch((err) => {
          console.log({ err });
          dispatch({ type: types.ERROR, payload: err.response.data });
        });

    case types.EDIT_LIST:
      return axios
        .put(
          `${API_URL}/lists/${action.payload.id}/edit`,
          action.payload,
          headers
        )
        .then((res) =>
          dispatch({
            type: types.EDIT_LIST,
            payload: res.data,
            data: action.payload,
          })
        )
        .catch((err) =>
          dispatch({ type: types.ERROR, payload: err.response.data })
        );

    case types.CREATE_LIST:
      return axios
        .post(`${API_URL}/lists/create`, action.payload, headers)
        .then((res) =>
          dispatch({
            type: types.CREATE_LIST,
            payload: res.data,
            data: action.payload,
          })
        )
        .catch((err) =>
          dispatch({ type: types.ERROR, payload: err.response.data })
        );

    case types.DELETE_LIST:
      return axios
        .delete(`${API_URL}/lists/${action.payload}/delete`, headers)
        .then((res) =>
          dispatch({
            type: types.DELETE_LIST,
            payload: res.data,
            data: action.payload,
          })
        )
        .catch((err) =>
          dispatch({ type: types.ERROR, payload: err.response.data })
        );

    case types.GET_LISTS:
      return axios
        .get(`${API_URL}/user/i/lists`, headers)
        .then((res) =>
          dispatch({
            type: types.GET_LISTS,
            payload: res.data,
            data: action.payload,
          })
        )
        .catch((err) =>
          dispatch({ type: types.ERROR, payload: err.response.data })
        );

    case types.GET_LIST:
      return axios
        .get(`${API_URL}/lists/${action.payload}`, headers)
        .then((res) => dispatch({ type: types.GET_LIST, payload: res.data }))
        .catch((err) =>
          dispatch({ type: types.ERROR, payload: err.response.data })
        );

    case types.GET_TREND:
      return axios
        .get(`${API_URL}/trend`)
        .then((res) => dispatch({ type: types.GET_TREND, payload: res.data }))
        .catch((err) =>
          dispatch({ type: types.ERROR, payload: err.response.data })
        );

    case types.SEARCH:
      return axios
        .post(`${API_URL}/trend`, action.payload)
        .then((res) => dispatch({ type: types.SEARCH, payload: res.data }))
        .catch((err) =>
          dispatch({ type: types.ERROR, payload: err.response.data })
        );

    case types.SEARCH_USERS:
      return axios
        .post(`${API_URL}/user`, action.payload)
        .then((res) =>
          dispatch({ type: types.SEARCH_USERS, payload: res.data })
        )
        .catch((err) =>
          dispatch({ type: types.ERROR, payload: err.response.data })
        );

    case types.TREND_CARVIBESS:
      return axios
        .get(`${API_URL}/trend/list/${action.payload}`)
        .then((res) =>
          dispatch({ type: types.TREND_CARVIBESS, payload: res.data })
        )
        .catch((err) =>
          dispatch({ type: types.ERROR, payload: err.response.data })
        );

    case types.ADD_TO_LIST:
      return axios
        .post(
          `${API_URL}/lists/${action.payload.username}/${action.payload.id}`,
          action.payload,
          headers
        )
        .then((res) =>
          dispatch({
            type: types.ADD_TO_LIST,
            payload: res.data,
            data: action.payload,
          })
        )
        .catch((err) =>
          dispatch({ type: types.ERROR, payload: err.response.data })
        );

    case types.GET_FOLLOWERS:
      return axios
        .get(`${API_URL}/user/${action.payload}/followers`, headers)
        .then((res) => {
          dispatch({
            type: types.GET_FOLLOWERS,
            payload: res.data,
            data: action.payload,
          });
        })
        .catch((err) =>
          dispatch({ type: types.ERROR, payload: err.response.data })
        );

    // case types.GET_FOLLOWING:
    //     return axios.get(`${API_URL}/lists/i/following`, action.payload, headers)
    //     .then(res=>dispatch({ type: types.GET_FOLLOWING, payload: res.data, data: action.payload }))
    //     .catch(err=>dispatch({ type: types.ERROR, payload: err.response.data }))

    case types.WHO_TO_FOLLOW:
      return axios
        .get(`${API_URL}/user/i/suggestions`, headers)
        .then((res) =>
          dispatch({
            type: types.WHO_TO_FOLLOW,
            payload: res.data,
            data: action.payload,
          })
        )
        .catch((err) =>
          dispatch({ type: types.ERROR, payload: err.response.data })
        );

    case types.GET_CONVERSATIONS:
      return axios
        .get(`${API_URL}/chat/conversations`, headers)
        .then((res) =>
          dispatch({ type: types.GET_CONVERSATIONS, payload: res.data })
        )
        .catch((err) =>
          dispatch({ type: types.ERROR, payload: err.response.data })
        );

    case types.START_CHAT:
      return axios
        .post(`${API_URL}/chat/conversation`, action.payload, headers)
        .then((res) =>
          dispatch({
            type: types.START_CHAT,
            payload: res.data,
            data: action.payload,
          })
        )
        .catch((err) =>
          dispatch({ type: types.ERROR, payload: err.response.data })
        );

    case types.GET_SINGLE_CONVERSATION:
      return axios
        .get(`${API_URL}/chat/conversation?id=${action.payload.id}`, headers)
        .then((res) =>
          dispatch({
            type: types.GET_SINGLE_CONVERSATION,
            payload: res.data,
            data: action.payload,
          })
        )
        .catch((err) =>
          dispatch({ type: types.ERROR, payload: err.response.data })
        );

    case types.REPORT:
      return axios
        .post(
          `${API_URL}/report/${action.payload.id}/report`,
          action.payload,
          headers
        )
        .then((res) =>
          dispatch({
            type: types.REPORT,
            payload: res.data,
            data: action.payload,
          })
        )
        .catch((err) =>
          dispatch({ type: types.ERROR, payload: err.response.data })
        );
    case types.DELETE_REPORT:
      return axios
        .delete(`${API_URL}/report/${action.payload}/delete`, headers)
        .then((res) =>
          dispatch({
            type: types.DELETE_REPORT,
            payload: res.data,
            data: action.payload,
          })
        )
        .catch((err) =>
          dispatch({ type: types.ERROR, payload: err.response.data })
        );
    case types.PROCESS_REPORT:
      return axios
        .post(
          `${API_URL}/report/${action.payload.id}/process`,
          action.payload,
          headers
        )
        .then((res) =>
          dispatch({
            type: types.PROCESS_REPORT,
            payload: res.data,
            data: action.payload,
          })
        )
        .catch((err) =>
          dispatch({ type: types.ERROR, payload: err.response.data })
        );
    case types.GET_REPORTS:
      return axios
        .get(`${API_URL}/user/i/reports`, headers)
        .then((res) =>
          dispatch({
            type: types.GET_REPORTS,
            payload: res.data,
            data: action.payload,
          })
        )
        .catch((err) =>
          dispatch({ type: types.ERROR, payload: err.response.data })
        );
    case types.GET_USERS:
      return axios
        .get(`${API_URL}/user/all`, headers)
        .then((res) =>
          dispatch({
            type: types.GET_USERS,
            payload: res.data,
            data: action.payload,
          })
        )
        .catch((err) =>
          dispatch({ type: types.ERROR, payload: err.response.data })
        );
    case types.WARN_USER:
      return axios
        .post(`${API_URL}/user/${action.payload}/warn`, action.payload, headers)
        .then((res) =>
          dispatch({
            type: types.WARN_USER,
            payload: res.data,
            data: action.payload,
          })
        )
        .catch((err) =>
          dispatch({ type: types.ERROR, payload: err.response.data })
        );
    case types.DELETE_USER:
      return axios
        .delete(`${API_URL}/user/${action.payload}`, headers)
        .then((res) =>
          dispatch({
            type: types.DELETE_USER,
            payload: res.data,
            data: action.payload,
          })
        )
        .catch((err) =>
          dispatch({ type: types.ERROR, payload: err.response.data })
        );
    case types.GET_LOGS:
      return axios
        .get(`${API_URL}/user/i/logs`, headers)
        .then((res) =>
          dispatch({
            type: types.GET_LOGS,
            payload: res.data,
            data: action.payload,
          })
        )
        .catch((err) =>
          dispatch({ type: types.ERROR, payload: err.response.data })
        );
    case types.SEND_NOTIFICATION:
      return axios
        .post(`${API_URL}/user/notification`, action.payload, headers)
        .then((res) => {
          console.log(res.data)
          dispatch({
            type: types.SEND_NOTIFICATION,
            payload: res.data,
            data: action.payload,
          })
        }
        )
        .catch((err) =>
          dispatch({ type: types.ERROR, payload: err.response.data })
        );
    default:
      dispatch(action);
  }
};
