import React, { useEffect, useContext, useState } from "react";
import { StoreContext } from "../../../store/store";
import "./style.scss";
import Loading from "../../loading";
import UserAvatar from "../../UserAvatar";
import { ICON_SEARCH } from "../../../Icons";
import { Link } from "react-router-dom";
import TableHeaderItem from '../../Admin/components/TableHeaderItem';
import TableItem from '../../Admin/components/TableItem';
import { timeDisplay } from '../../../utils/common';

const UserList = () => {
  const { state, actions } = useContext(StoreContext);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState([]);

  const surveyKeys = {
    'mileage': 'Mileage',
    'yearType': 'Year Type',
    'reasonForChooseCar': 'Reason For Choose Car',
    'repairHistory': 'Repair History',
    'companionTime': 'Companion Time',
    'nextCarType': 'Next Car Type',
    'carMeansToYou': 'Car Means To You',
    'specificGenresWillSpendingOn': 'Specific Genres Spending On',
    'annualIncome': 'Annual Income',
    'howMuchSpendOnCar': 'How Much Spend On Car',
    'occupation': 'Occupation',
    'createdAt': 'CreatedAt',
    'updatedAt': 'UpdatedAt',
  };

  const baseColumnsWidth = 900;
  const surveyColumnsWidth = Object.keys(surveyKeys).length * 150;
  const totalWidth = baseColumnsWidth + surveyColumnsWidth;

  useEffect(() => {
    window.scrollTo(0, 0);
    const currentToken = localStorage.getItem("carvibestoken");
    if (currentToken && currentToken.length > 0) {
      actions.getUsers();
    } else {
      window.location = "/admin-login";
    }
    setTimeout(() => {
      setLoading(false);
    }, 500);
  }, []);

  useEffect(() => {
    const results = state.users.filter(user =>
      user._id.toLowerCase().includes(searchTerm) ||
      user.name.toLowerCase().includes(searchTerm)
    );
    setSearchResults(results);
  }, [searchTerm, state.users]);

  if (loading) return Loading();

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const tableHeader = () => {
    return (
      <div className="admin-carvibes-cell-title" style={{
        flexDirection: "row",
        height: "40px",
        backgroundColor: "#F7FAFC",
        width: `${totalWidth}px`,
        display: 'flex',
      }}>
        <TableHeaderItem title="ユーザーID" width="300px" />
        <TableHeaderItem title="ユーザー名" width="300px" />
        <TableHeaderItem title="Email" width="300px" />
        {Object.values(surveyKeys).map((value) => (
          <TableHeaderItem key={value} title={value} width="150px" />
        ))}
      </div>
    );
  };

  return (
    <div className="admin-wrapper" style={{
      backgroundColor: "#fff"
    }}>
      <div className="admin-header-wrapper">
        <h2 className="admin-title">ユーザー一覧</h2>
      </div>
      <div className="explore-search-wrapper">
        <div className="explore-search-icon">
          <ICON_SEARCH />
        </div>
        <div className="explore-search-input">
          <input placeholder="Search" type="text" name="search" value={searchTerm} onChange={handleSearch} />
        </div>
      </div>
      <div className="admin-content-wrapper" style={{ overflow: 'hidden' }}>
        <div style={{ overflowX: 'auto', width: '100%' }}>
          {tableHeader()}
          <div style={{ width: `${totalWidth}px` }}>
            {state.users.length > 0 ? (
              searchResults.map((t) => {
                const surveyData = t.survey && t.survey[0] ? t.survey[0] : {};
                return (
                  <div
                    style={{
                      display: 'flex',
                      cursor: 'pointer',
                      width: '100%',
                    }}
                    key={t._id}
                  >
                    <Link
                      to={{ pathname: `/admin/user-list/${t._id}`, state: { user: t } }}
                      style={{
                        display: 'flex',
                        textDecoration: 'none',
                        color: 'inherit',
                        width: '100%'
                      }}
                    >
                      <div className="admin-carvibes-cell" style={{
                        display: 'flex',
                        width: '100%',
                        minWidth: `${totalWidth}px`
                      }}>
                        <TableItem title={t._id} width="300px" />
                        <div
                          className="card-userPic-wrapper"
                          style={{
                            width: "300px",
                            margin: 0,
                            flexBasis: "unset",
                            display: 'flex',
                            alignItems: 'center',
                          }}>
                          <UserAvatar
                            user={t}
                            size={32}
                          />
                          <TableItem title={t.name} fontSize="14px" />
                        </div>
                        <TableItem title={t.email} width="300px" />
                        {Object.keys(surveyKeys).map((key) => {
                          let value = surveyData[key];
                          if (key === 'createdAt' || key === 'updatedAt') {
                            value = value ? timeDisplay(value) : '';
                          } else {
                            value = value ? String(value) : '';
                          }
                          return (
                            <TableItem
                              key={key}
                              title={value}
                              width="150px"
                            />
                          );
                        })}
                      </div>
                    </Link>
                  </div>
                );
              })
            ) : (
              <div style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100px",
                width: "100%",
              }}>
                <span style={{
                  fontSize: "16px",
                  fontWeight: "400",
                }}>登録されたユーザーがいません。</span>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserList;