export default {
  SET_STATE: "SET_STATE",
  GOOGLE_LOGIN: "GOOGLE_LOGIN",
  FACEBOOK_LOGIN: "FACEBOOK_LOGIN",
  ADMIN_LOGIN: "ADMIN_LOGIN",
  REGISTER: "REGISTER",
  CARVIBES: "CARVIBES",
  GET_CARVIBESS: "GET_CARVIBESS",
  LIKE_CARVIBES: "LIKE_CARVIBES",
  BOOKMARK: "BOOKMARK",
  GET_CARVIBES: "GET_CARVIBES",
  GET_ACCOUNT: "GET_ACCOUNT",
  GET_USER: "GET_USER",
  GET_BOOKMARKS: "GET_BOOKMARKS",
  UPDATE_USER: "UPDATE_USER",
  RECARVIBES: "RECARVIBES",
  DELETE_CARVIBES: "DELETE_CARVIBES",
  FOLLOW_USER: "FOLLOW_USER",
  FOLLOWER_USER: "FOLLOWER_USER",
  EDIT_LIST: "EDIT_LIST",
  CREATE_LIST: "CREATE_LIST",
  DELETE_LIST: "DELETE_LIST",
  GET_LISTS: "GET_LISTS",
  LOG_OUT: "LOG_OUT",
  GET_LIST: "GET_LIST",
  GET_TREND: "GET_TREND",
  SEARCH: "SEARCH",
  TREND_CARVIBESS: "TREND_CARVIBESS",
  ADD_TO_LIST: "ADD_TO_LIST",
  GET_FOLLOWERS: "GET_FOLLOWERS",
  GET_FOLLOWING: "GET_FOLLOWING",
  SEARCH_USERS: "SEARCH_USERS",
  WHO_TO_FOLLOW: "WHO_TO_FOLLOW",
  GET_CONVERSATIONS: "GET_CONVERSATIONS",
  START_CHAT: "START_CHAT",
  GET_SINGLE_CONVERSATION: "GET_SINGLE_CONVERSATION",
  ERROR: "ERROR",
  RESET_ERROR: "RESET_ERROR",
  REPORT: "REPORT",
  GET_REPORTS: "GET_REPORTS",
  PROCESS_REPORT: "PROCESS_REPORT",
  DELETE_REPORT: "DELETE_REPORT",
  UPDATE_REPORT: "UPDATE_REPORT",
  GET_USERS: "GET_USERS",
  WARN_USER: "WARN_USER",
  DELETE_USER: "DELETE_USER",
  GET_LOGS: "GET_LOGS",
  SEND_NOTIFICATION: "SEND_NOTIFICATION",
  EDIT_CARVIBES: "EDIT_CARVIBES"
};
